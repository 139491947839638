var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-1",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: {
            shown: function($event) {
              _vm.activeModal = "verify-tank-id"
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-step-1-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-1-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button",
                              attrs: {
                                variant: "icon-button--gray",
                                "data-test-hook":
                                  "tank-enrollment-step-1-close-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.areYouSure(
                                    "tank-enrollment-step-1"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled:
                          !_vm.validState(_vm.$v.userEnteredTankDeviceId) ||
                          _vm.validatingId,
                        "data-test-hook": "tank-enrollment-step-1-next-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.validateDeviceId(), _vm.helpStep2++
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xNext")) + " "),
                      _vm.validatingId
                        ? _c("b-spinner", { staticClass: "ml-2" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.helpStep1 > 1
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { staticClass: "tu-help-alert", attrs: { show: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h3", [
                                  _c("i", { staticClass: "fas fa-phone-alt" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xNeedHelpContactSupport")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xUsAndCanadaPhoneNumbers")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mb-0", attrs: { "align-v": "center" } },
            [
              _c("b-col", [
                _c(
                  "h5",
                  {
                    attrs: {
                      "data-test-hook":
                        "tank-enrollment-step-1-enter-tank-id-text"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xEnterTankDeviceId")) + " ")]
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "deviceId-group" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "deviceId",
                          autocomplete: "new-password",
                          state:
                            _vm.validState(_vm.$v.userEnteredTankDeviceId) &&
                            _vm.validFuelMonitorId(
                              _vm.$v.userEnteredTankDeviceId.$model
                            ),
                          "aria-describedby": "deviceId-invalid",
                          "data-test-hook":
                            "tank-enrollment-step-1-tank-id-field"
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            _vm.validateDeviceId(),
                              _vm.helpStep1++,
                              _vm.helpStep2++
                          }
                        },
                        model: {
                          value: _vm.$v.userEnteredTankDeviceId.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.userEnteredTankDeviceId,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.userEnteredTankDeviceId.$model"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "deviceId-invalid" } },
                        [
                          _c(
                            "span",
                            {
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-1-invalid-tank-id"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("xYourTankDeviceIDMust")))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.showValidateDeviceIdError
            ? _c(
                "div",
                {
                  staticClass: "alert alert--validation alert-danger",
                  attrs: { role: "alert", show: _vm.showValidateDeviceIdError }
                },
                [_vm._v(" " + _vm._s(_vm.validateDeviceIdErrorMessage) + " ")]
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-3 pr-0" },
                [
                  _c("b-progress", {
                    attrs: {
                      value: 11.11,
                      variant: "primary",
                      "data-test-hook": "tank-enrollment-step-1-progress-bar"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-2",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: {
            shown: function($event) {
              _vm.activeModal = "enter-name-and-address"
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-step-2-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-2-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button",
                              attrs: {
                                variant: "icon-button--gray",
                                "data-test-hook":
                                  "tank-enrollment-step-2-close-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.areYouSure(
                                    "tank-enrollment-step-2"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook":
                          "tank-enrollment-step-2-previous-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.$bvModal.show("tank-enrollment-step-1"),
                            (_vm.editingTankAddress = false),
                            _vm.trackClick("VerifyTankName")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled:
                          _vm.enrollingTank ||
                          !_vm.isValidAddress ||
                          !_vm.tankName,
                        "data-test-hook": "tank-enrollment-step-2-next-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.createApparatusAndProceed(),
                            (_vm.editingTankAddress = false),
                            _vm.helpStep3++
                        }
                      }
                    },
                    [
                      !_vm.enrollingTank
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("xNext")))])
                        : _c("b-spinner")
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.helpStep2 > 2
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { staticClass: "tu-help-alert", attrs: { show: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h3", [
                                  _c("i", { staticClass: "fas fa-phone-alt" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xNeedHelpContactSupport")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xUsAndCanadaPhoneNumbers")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "h5",
                  {
                    attrs: {
                      "data-test-hook":
                        "tank-enrollment-step-2-verify-tank-name-text"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("xVerifyTankNameAndAddress")))]
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "tankTame",
                          placeholder: _vm.$t("xTankName"),
                          maxLength: "50",
                          "data-test-hook":
                            "tank-enrollment-step-2-tank-name-field"
                        },
                        model: {
                          value: _vm.tankName,
                          callback: function($$v) {
                            _vm.tankName = $$v
                          },
                          expression: "tankName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.editingTankAddress
            ? _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c("b-col", [
                    _c("p", [
                      _c(
                        "strong",
                        {
                          attrs: {
                            "data-test-hook":
                              "tank-enrollment-step-2-tank-address-label"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("formatLabel")("xTankAddress")) +
                              " "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          !_vm.editingTankAddress
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "p",
                      {
                        attrs: {
                          "data-test-hook":
                            "tank-enrollment-step-2-tank-address"
                        }
                      },
                      [_vm._v(_vm._s(_vm.DisplayAddress(_vm.tankAddress)))]
                    )
                  ]),
                  _c(
                    "b-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "icon-button",
                            "data-test-hook":
                              "tank-enrollment-step-2-edit-address-button"
                          },
                          on: {
                            click: function($event) {
                              _vm.editingTankAddress = !_vm.editingTankAddress
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-pencil" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("TypeAheadAddress", {
                        ref: "typeAheadAddressEditTankAddress",
                        attrs: { currentAddress: _vm.tankAddress },
                        on: { newAddress: _vm.setCurrentAddress }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
          _vm.showEnrollTankApparatusError
            ? _c(
                "div",
                {
                  staticClass: "alert alert--validation alert-danger",
                  attrs: {
                    role: "alert",
                    show: _vm.showEnrollTankApparatusError
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.enrollTankApparatusErrorMessage) + " "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-3 pr-0" },
                [
                  _c("b-progress", {
                    attrs: {
                      value: 22.22,
                      variant: "primary",
                      "data-test-hook": "tank-enrollment-step-2-progress-bar"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-3",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: {
            shown: function($event) {
              _vm.activeModal = "enter-fueltype-and-orientation"
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-step-3-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-3-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button",
                              attrs: {
                                variant: "icon-button--gray",
                                "data-test-hook":
                                  "tank-enrollment-step-3-close-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.areYouSure(
                                    "tank-enrollment-step-3"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled:
                          _vm.userSelectedFuelType == null ||
                          _vm.userSelectedTankOrien == null,
                        "data-test-hook": "tank-enrollment-step-3-next-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.loadTankCapacities(), _vm.helpStep4++
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xNext")) + " "),
                      _vm.loadingTankCapacities
                        ? _c("b-spinner", {
                            staticStyle: { width: "1.5rem", height: "1.5rem" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.helpStep3 > 2
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { staticClass: "tu-help-alert", attrs: { show: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h3", [
                                  _c("i", { staticClass: "fas fa-phone-alt" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xNeedHelpContactSupport")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xUsAndCanadaPhoneNumbers")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mb-0", attrs: { "align-v": "center" } },
            [
              _c("b-col", [
                _c(
                  "h5",
                  {
                    attrs: {
                      "data-test-hook":
                        "tank-enrollment-step-3-enter-tank-details-text"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xEnterYourTankDetails")) + " ")]
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "p",
                  {
                    attrs: {
                      "data-test-hook":
                        "tank-enrollment-step-3-select-fuel-type-text"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("xSelectYourFuelType")))]
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "w-100 mb-3",
              attrs: { "align-v": "center", "no-gutters": "" }
            },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button-group",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-button",
                        {
                          class: {
                            active: _vm.userSelectedFuelType == _vm.PROPANE
                          },
                          attrs: {
                            variant: "tank-outline",
                            "data-test-hook":
                              "tank-enrollment-step-3-fuel-type-propane-button"
                          },
                          on: {
                            click: function($event) {
                              ;(_vm.userSelectedFuelType = _vm.PROPANE),
                                (_vm.userSelectedTankOrien = null)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("xPropane")) + " ")]
                      ),
                      _c(
                        "b-button",
                        {
                          class: {
                            active: _vm.userSelectedFuelType == _vm.HEATINGOIL
                          },
                          attrs: {
                            variant: "tank-outline",
                            "data-test-hook":
                              "tank-enrollment-step-3-fuel-type-oil-button"
                          },
                          on: {
                            click: function($event) {
                              ;(_vm.userSelectedFuelType = _vm.HEATINGOIL),
                                (_vm.userSelectedTankOrien = _vm.HORIZONTAL)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("xOil")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "h6",
                  {
                    attrs: {
                      "data-test-hook":
                        "tank-enrollment-step-3-tank-orientation-text"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("xSelectTankOrien")))]
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "w-100 mb-3",
              attrs: { "align-v": "center", "no-gutters": "" }
            },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "py-0 mx-0",
                      staticStyle: { height: "80px" },
                      attrs: {
                        block: "",
                        variant: "tank-outline",
                        disabled: _vm.userSelectedFuelType == _vm.HEATINGOIL,
                        pressed: _vm.userSelectedTankOrien == _vm.VERTICAL,
                        "data-test-hook":
                          "tank-enrollment-step-3-tank-orientation-vertical-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.userSelectedTankOrien = _vm.VERTICAL
                        }
                      }
                    },
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-v": "center" } },
                        [
                          _c(
                            "b-col",
                            [
                              _vm.userSelectedTankOrien == _vm.VERTICAL
                                ? _c("b-img", {
                                    attrs: {
                                      height: "50px",
                                      src: require("@/assets/images/tank-vertical-selected.svg"),
                                      "data-test-hook":
                                        "tank-enrollment-step-3-tank-orientation-vertical-image"
                                    }
                                  })
                                : _vm._e(),
                              _vm.userSelectedTankOrien != _vm.VERTICAL &&
                              (_vm.userSelectedFuelType == _vm.PROPANE ||
                                _vm.userSelectedFuelType == null)
                                ? _c("b-img", {
                                    attrs: {
                                      height: "50px",
                                      src: require("@/assets/images/tank-vertical-unselected.svg"),
                                      "data-test-hook":
                                        "tank-enrollment-step-3-tank-orientation-vertical-image"
                                    }
                                  })
                                : _vm._e(),
                              _vm.userSelectedFuelType == _vm.HEATINGOIL
                                ? _c("b-img", {
                                    attrs: {
                                      height: "50px",
                                      src: require("@/assets/images/tank-vertical-disabled.svg"),
                                      "data-test-hook":
                                        "tank-enrollment-step-3-tank-orientation-vertical-image"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-3-tank-orientation-vertical-button-text"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("xVertical")) + " ")]
                          ),
                          _c("b-col")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "w-100",
              attrs: { "align-v": "center", "no-gutters": "" }
            },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "py-0 mx-0",
                      staticStyle: { height: "80px" },
                      attrs: {
                        id: "tank-orien-horz",
                        block: "",
                        variant: "tank-outline",
                        pressed:
                          _vm.userSelectedFuelType == _vm.HEATINGOIL ||
                          _vm.userSelectedTankOrien == _vm.HORIZONTAL,
                        "data-test-hook":
                          "tank-enrollment-step-3-tank-orientation-horizontal-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.userSelectedTankOrien = _vm.HORIZONTAL
                        }
                      }
                    },
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-v": "center" } },
                        [
                          _c(
                            "b-col",
                            [
                              _vm.userSelectedTankOrien == _vm.HORIZONTAL
                                ? _c("b-img", {
                                    attrs: {
                                      height: "50px",
                                      src: require("@/assets/images/tank-horizontal-selected.svg"),
                                      "data-test-hook":
                                        "tank-enrollment-step-3-tank-orientation-horizontal-image"
                                    }
                                  })
                                : _c("b-img", {
                                    attrs: {
                                      height: "50px",
                                      src: require("@/assets/images/tank-horizontal-unselected.svg"),
                                      "data-test-hook":
                                        "tank-enrollment-step-3-tank-orientation-horizontal-image"
                                    }
                                  })
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-3-tank-orientation-horizontal-button-text"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("xHorizontal")) + " ")]
                          ),
                          _c("b-col")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-3 pr-0" },
                [
                  _c("b-progress", {
                    attrs: {
                      value: 33.33,
                      variant: "primary",
                      "data-test-hook": "tank-enrollment-step-3-progress-bar"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-4",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: {
            shown: function($event) {
              _vm.activeModal = "enter-capacity"
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-step-4-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-4-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button",
                              attrs: {
                                variant: "icon-button--gray",
                                "data-test-hook":
                                  "tank-enrollment-step-4-close-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.areYouSure(
                                    "tank-enrollment-step-4"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook":
                          "tank-enrollment-step-4-previous-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.$bvModal.show("tank-enrollment-step-3"),
                            _vm.trackClick("SelectTankCapacity"),
                            _vm.helpStep3++
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled: _vm.userSelectedTankCapacity == null,
                        "data-test-hook": "tank-enrollment-step-4-next-button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.wifiSetupNavigation()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xNext")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.helpStep4 > 2
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { staticClass: "tu-help-alert", attrs: { show: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h3", [
                                  _c("i", { staticClass: "fas fa-phone-alt" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xNeedHelpContactSupport")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xUsAndCanadaPhoneNumbers")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mb-0", attrs: { "align-v": "center" } },
            [
              _c("b-col", [
                _c(
                  "h5",
                  {
                    attrs: {
                      "data-test-hook":
                        "tank-enrollment-step-4-select-tank-capacity-text"
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("xSelectYourTankCapacity")) + " "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "fieldset",
                {
                  staticClass: "form-group",
                  attrs: { id: "tank-capacity-group" }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.userSelectedTankCapacity.$model,
                          expression: "$v.userSelectedTankCapacity.$model"
                        }
                      ],
                      staticClass: "custom-select",
                      attrs: {
                        id: "tank-capacity",
                        placeholder: _vm.$t("xSelectCapacity"),
                        state: _vm.validState(_vm.$v.userSelectedTankCapacity),
                        "aria-describedby": "capacity-invalid",
                        "data-test-hook":
                          "tank-enrollment-step-4-tank-capacity-field"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.$v.userSelectedTankCapacity,
                            "$model",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      !_vm.userSelectedTankCapacity
                        ? _c("option", {
                            attrs: { disabled: "" },
                            domProps: { value: null }
                          })
                        : _vm._e(),
                      _vm._l(_vm.tankCapacities, function(capacity, index) {
                        return _c(
                          "option",
                          {
                            key: index,
                            attrs: {
                              "data-test-hook":
                                "tankCapacity" +
                                _vm.userSelectedFuelType +
                                _vm.userSelectedTankOrien +
                                capacity.value
                            },
                            domProps: { value: capacity.value }
                          },
                          [_vm._v(" " + _vm._s(capacity.text) + " ")]
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "capacity-invalid" } },
                    [_vm._v(" " + _vm._s(_vm.$t("xRequiredField")) + ". ")]
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "b-row",
            {
              staticClass: "w-100 mb-3",
              attrs: { "align-v": "center", "no-gutters": "" }
            },
            [_c("b-col")],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-3 pr-0" },
                [
                  _c("b-progress", {
                    attrs: {
                      value: 44.44,
                      variant: "primary",
                      "data-test-hook": "tank-enrollment-step-4-progress-bar"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-5",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: {
            shown: function($event) {
              _vm.activeModal = "confirm-tank-install"
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-step-5-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-5-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button",
                              attrs: {
                                variant: "icon-button--gray",
                                "data-test-hook":
                                  "tank-enrollment-step-5-close-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.areYouSure(
                                    "tank-enrollment-step-5"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook":
                          "tank-enrollment-step-5-previous-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.$bvModal.show("tank-enrollment-step-4"),
                            _vm.trackClick("ConfirmMonitorInstallation"),
                            _vm.helpStep4++
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        "data-test-hook": "tank-enrollment-step-5-next-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.getTankReading(),
                            _vm.$bvModal.show("tank-enrollment-step-6"),
                            _vm.helpStep6++
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xNext")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.helpStep5 > 2
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { staticClass: "tu-help-alert", attrs: { show: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h3", [
                                  _c("i", { staticClass: "fas fa-phone-alt" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xNeedHelpContactSupport")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xUsAndCanadaPhoneNumbers")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "my-0" },
            [
              _c(
                "b-col",
                [
                  _c("b-embed", {
                    attrs: {
                      type: "iframe",
                      src: _vm.tankMonitorInstallVideoUrl(),
                      allowfullscreen: "",
                      "data-test-hook":
                        "tank-enrollment-step-5-monitor-install-video"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-0 mt-3" },
            [
              _c("b-col", [
                _c(
                  "h5",
                  {
                    attrs: {
                      "data-test-hook":
                        "tank-enrollment-step-5-confirm-monitor-install-text"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("xConfirmTankMonitorInstallation")) +
                        " "
                    )
                  ]
                ),
                _c("ul", { staticClass: "mt-3 bullet-points" }, [
                  _c(
                    "li",
                    {
                      attrs: {
                        "data-test-hook":
                          "tank-enrollment-step-5-sensor-attached-text"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("xEnsureTankMonitorSensorIsAttached")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "li",
                    {
                      attrs: {
                        "data-test-hook":
                          "tank-enrollment-step-5-magnet-to-trigger-reading-text"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("xUseTheMagnetToTriggerAReading")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "li",
                    {
                      attrs: {
                        "data-test-hook":
                          "tank-enrollment-step-5-you-will-know-text"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xYouWillKnowAReading")) + " ")]
                  ),
                  _c(
                    "li",
                    {
                      attrs: {
                        "data-test-hook":
                          "tank-enrollment-step-5-tap-next-to-continue-text"
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("xTapNextBelowToContinue")) + " "
                      )
                    ]
                  )
                ])
              ])
            ],
            1
          ),
          _vm.showTankReadingError
            ? _c(
                "div",
                {
                  staticClass: "alert alert-danger alert--validation",
                  attrs: { show: _vm.showTankReadingError }
                },
                [_vm._v(" " + _vm._s(_vm.tankReadingErrorMessage) + " ")]
              )
            : _vm._e(),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-3 pr-0" },
                [
                  _c("b-progress", {
                    attrs: {
                      value: 55.55,
                      variant: "primary",
                      "data-test-hook": "tank-enrollment-step-5-progress-bar"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-6",
            size: "md",
            "hide-footer": "",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: {
            shown: function($event) {
              _vm.activeModal = "wait-for-tank-reading"
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-step-6-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-6-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button",
                              attrs: {
                                variant: "icon-button--gray",
                                "data-test-hook":
                                  "tank-enrollment-step-6-close-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.areYouSure(
                                    "tank-enrollment-step-6"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.helpStep6 > 2
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { staticClass: "tu-help-alert", attrs: { show: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h3", [
                                  _c("i", { staticClass: "fas fa-phone-alt" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xNeedHelpContactSupport")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xUsAndCanadaPhoneNumbers")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c("b-col", [
                _c(
                  "h5",
                  {
                    staticClass: "text-center",
                    attrs: {
                      "data-test-hook":
                        "tank-enrollment-step-6-wait-for-reading-text"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "xPleaseWaitForYourTankMonitorToReceiveAReading"
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "b-col",
                [
                  _c("lottie", {
                    attrs: {
                      options: _vm.waitingForTankReadingAnimationOptions,
                      height: 200,
                      width: 200,
                      "data-test-hook":
                        "tank-enrollment-step-6-wait-for-reading-animation"
                    },
                    on: { animCreated: _vm.handleAnimation }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "shouldnt-take-long",
              attrs: {
                "align-h": "center",
                "data-test-hook":
                  "tank-enrollment-step-6-shouldnt-take-long-text"
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("xThisShouldntTakeLong")) + " ")]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-7",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: {
            shown: function($event) {
              _vm.activeModal = "tank-reading-received"
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-step-7-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-7-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button",
                              attrs: {
                                variant: "icon-button--gray",
                                "data-test-hook":
                                  "tank-enrollment-step-7-close-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.areYouSure(
                                    "tank-enrollment-step-7"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook":
                          "tank-enrollment-step-7-verify-reading-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.$bvModal.show("tank-enrollment-step-8"),
                            _vm.helpStep8++
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xVerifyTankReading")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        "data-test-hook":
                          "tank-enrollment-step-7-looks-good-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.$bvModal.show("tank-enrollment-step-9"),
                            _vm.helpStep9++
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xLooksGood")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.helpStep7 > 2
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { staticClass: "tu-help-alert", attrs: { show: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h3", [
                                  _c("i", { staticClass: "fas fa-phone-alt" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xNeedHelpContactSupport")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xUsAndCanadaPhoneNumbers")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c("b-col", [
                _c(
                  "h1",
                  {
                    staticClass: "text-center",
                    attrs: {
                      "data-test-hook":
                        "tank-enrollment-step-7-reading-received-text"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xReadingReceived")) + " ")]
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-2", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                {
                  staticClass: "colorGray",
                  staticStyle: { "font-size": "6rem", "padding-right": "5px" },
                  attrs: {
                    cols: "auto",
                    "data-test-hook": "tank-enrollment-step-7-tank-level"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.tankReadingLevel) + " ")]
              ),
              _c(
                "b-col",
                {
                  staticClass: "pl-0 colorGray",
                  staticStyle: {
                    "font-size": "3rem",
                    "padding-top": "25px",
                    "margin-top": "0px"
                  },
                  attrs: { cols: "auto" }
                },
                [_vm._v("%")]
              )
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "shouldnt-take-long",
              attrs: { "align-h": "center" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("xCurrentTankReading")) + " ")]
          ),
          _c(
            "b-row",
            {
              staticClass: "mt-3 mb-2 mx-3 verify-reading",
              attrs: { "align-h": "center" }
            },
            [_c("p", [_vm._v(_vm._s(_vm.$t("xIfThisReadingDoesntLookRight")))])]
          ),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-3 pr-0" },
                [
                  _c("b-progress", {
                    attrs: {
                      value: 66.66,
                      variant: "primary",
                      "data-test-hook": "tank-enrollment-step-7-progress-bar"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-8",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: {
            shown: function($event) {
              _vm.activeModal = "user-verify-tank-reading"
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "modal-header",
                fn: function() {
                  return [
                    _c(
                      "b-row",
                      {
                        staticClass: "w-100",
                        attrs: { "align-v": "center", "no-gutters": "" }
                      },
                      [
                        _c("b-col", [
                          _c(
                            "h2",
                            {
                              staticClass: "modal-title",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-8-header"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "colorOrange fas fa-plus mr-1",
                                attrs: {
                                  "data-test-hook":
                                    "tank-enrollment-step-8-header-icon"
                                }
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                            ]
                          )
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-icon-button",
                                attrs: {
                                  variant: "icon-button--gray",
                                  "data-test-hook":
                                    "tank-enrollment-step-8-close-button"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.areYouSure(
                                      "tank-enrollment-step-8"
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fas fa-times" })]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              _vm.verifyTankReadingSuccess == false
                ? {
                    key: "modal-footer",
                    fn: function() {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "outline-warning",
                              "data-test-hook":
                                "tank-enrollment-step-8-try-later-button"
                            },
                            on: {
                              click: function($event) {
                                _vm.$bvModal.show("tank-enrollment-step-9"),
                                  _vm.helpStep9++
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("xTryLater")) + " ")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "primary",
                              disabled: !_vm.tankLevelValidationDone,
                              "data-test-hook":
                                "tank-enrollment-step-8-try-again-button"
                            },
                            on: { click: _vm.tryEnterTankLevelAgain }
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("xTryAgain")))])]
                        )
                      ]
                    },
                    proxy: true
                  }
                : {
                    key: "modal-footer",
                    fn: function() {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "outline-warning",
                              "data-test-hook":
                                "tank-enrollment-step-8-previous-button"
                            },
                            on: {
                              click: function($event) {
                                _vm.$bvModal.show("tank-enrollment-step-7"),
                                  _vm.trackClick("Verify-Tank-Level-Reading"),
                                  _vm.helpStep7++
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "primary",
                              disabled: !_vm.tankLevelValidationDone,
                              "data-test-hook":
                                "tank-enrollment-step-8-next-button"
                            },
                            on: {
                              click: function($event) {
                                _vm.$bvModal.show("tank-enrollment-step-9"),
                                  _vm.helpStep9++
                              }
                            }
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("xNext")))])]
                        )
                      ]
                    },
                    proxy: true
                  }
            ],
            null,
            true
          )
        },
        [
          _vm.helpStep8 > 2
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { staticClass: "tu-help-alert", attrs: { show: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "h3",
                                  {
                                    attrs: {
                                      "data-test-hook":
                                        "tank-enrollment-step-8-help-alert"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-phone-alt"
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("xNeedHelpContactSupport")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xUsAndCanadaPhoneNumbers")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _vm.verifyTankReadingSuccess == null
                    ? _c("div", [
                        _c("h5", [
                          _c(
                            "span",
                            {
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-8-body-header"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("xEnterYourCurrentTankLevel")) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c(
                          "p",
                          {
                            attrs: {
                              "data-test-hook":
                                "tank-enrollment-step-8-body-text"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("xWhileStandingNextToYourTank"))
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.verifyTankReadingSuccess == true
                    ? _c(
                        "b-alert",
                        { attrs: { show: "", variant: "success" } },
                        [
                          _c(
                            "h5",
                            {
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-8-success-header"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "colorGreen fas fa-check-circle mr-1"
                              }),
                              _vm._v(_vm._s(_vm.$t("xSuccess")) + " ")
                            ]
                          ),
                          _c("b-row", { staticClass: "ml-0" }, [
                            _c(
                              "span",
                              {
                                attrs: {
                                  "data-test-hook":
                                    "tank-enrollment-step-8-success-body"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "xYourTankMonitorIsProperlyInstalled"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.verifyTankReadingSuccess == false
            ? _c(
                "div",
                [
                  _c(
                    "b-alert",
                    { attrs: { show: "", variant: "danger" } },
                    [
                      _c(
                        "h5",
                        {
                          attrs: {
                            "data-test-hook":
                              "tank-enrollment-step-8-error-header"
                          }
                        },
                        [
                          _c("i", {
                            staticClass:
                              "colorRed fas fa-exclamation-triangle mr-1"
                          }),
                          _vm._v(_vm._s(_vm.$t("xError")) + " ")
                        ]
                      ),
                      _c("b-row", { staticClass: "ml-0" }, [
                        _c(
                          "span",
                          {
                            attrs: {
                              "data-test-hook":
                                "tank-enrollment-step-8-error-body"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "xThereWasAnErrorVerifyingYourTankReading"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c("b-row", { staticClass: "ml-1 mt-2" }, [
                    _c(
                      "b",
                      {
                        attrs: {
                          "data-test-hook":
                            "tank-enrollment-step-8-best-results-header"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("xForBestResults")))]
                    )
                  ]),
                  _c("ul", { staticClass: "bullet-points mr-1" }, [
                    _c(
                      "li",
                      {
                        attrs: {
                          "data-test-hook":
                            "tank-enrollment-step-8-best-results-help1"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("xEnsureTankMonitorSensorIsAttached")
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "li",
                      {
                        attrs: {
                          "data-test-hook":
                            "tank-enrollment-step-8-best-results-help2"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("xUseTheMagnetToTriggerAReading")))]
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-4 bold-text" },
            [
              _c("b-col", { staticClass: "text-center" }, [
                _vm._v(" " + _vm._s(_vm.$t("xTankDialLevel")) + " ")
              ]),
              _c("b-col", { staticClass: "text-center" }, [
                _vm._v(" " + _vm._s(_vm.$t("xTankMonitorReading")) + " ")
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 mb-2" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { cols: "6" }
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { id: "tankDialLevel-group", append: "%" } },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##",
                            expression: "'##'"
                          }
                        ],
                        attrs: {
                          id: "tankDialLevel",
                          type: "text",
                          size: "lg",
                          autofocus: "",
                          state: _vm.validState(
                            _vm.$v.userEnteredTankDialLevel
                          ),
                          "aria-describedby": "tankDialLevel-invalid",
                          "data-test-hook":
                            "tank-enrollment-step-8-dial-level-field"
                        },
                        on: {
                          keydown: function($event) {
                            _vm.verifyTankReadingSuccess = null
                          }
                        },
                        model: {
                          value: _vm.$v.userEnteredTankDialLevel.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.userEnteredTankDialLevel,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.userEnteredTankDialLevel.$model"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "tankDialLevel-invalid" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("xEnterValueDisplayedOnTankDial")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "6" } },
                [
                  _c(
                    "b-input-group",
                    { attrs: { id: "tankReadingLevel-group", append: "%" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "tankReadingLevel",
                          type: "text",
                          size: "lg",
                          disabled: "",
                          autofocus: "",
                          value: _vm.tankReadingLevel,
                          "data-test-hook":
                            "tank-enrollment-step-8-tank-reading-level-field"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-col", { staticClass: "mt-3 pl-0", attrs: { cols: "auto" } })
            ],
            1
          ),
          !_vm.verifyTankReadingSuccess
            ? _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            block: "",
                            variant: "primary",
                            disabled:
                              !_vm.userEnteredTankDialLevel ||
                              _vm.userEnteredTankDialLevel < 1 ||
                              _vm.userEnteredTankDialLevel > 100,
                            "data-test-hook":
                              "tank-enrollment-step-8-verify-button"
                          },
                          on: {
                            click: function($event) {
                              _vm.verifyTankReading(),
                                (_vm.tankLevelValidationDone = true)
                            }
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("xVerify")))])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-3 pr-0" },
                [
                  _c("b-progress", {
                    attrs: {
                      value: 77.77,
                      variant: "primary",
                      "data-test-hook": "tank-enrollment-step-8-progress-bar"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-9",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: {
            shown: function($event) {
              _vm.activeModal = "specify-tank-usage"
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-step-9-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-9-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button",
                              attrs: {
                                variant: "icon-button--gray",
                                "data-test-hook":
                                  "tank-enrollment-step-9-close-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.areYouSure(
                                    "tank-enrollment-step-9"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _vm.tankDeviceType &&
                  _vm.tankDeviceType != _vm.wifiTankDeviceType
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-warning",
                            "data-test-hook":
                              "tank-enrollment-step-9-previous-button"
                          },
                          on: {
                            click: function($event) {
                              ;(_vm.userEnteredTankDeviceId = null),
                                (_vm.verifyTankReadingSuccess = null),
                                _vm.$bvModal.show("tank-enrollment-step-7"),
                                _vm.helpStep7++
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        "data-test-hook": "tank-enrollment-step-9-next-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.checkFuelUseSelected(), _vm.helpStep10++
                        }
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("xNext")))])]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.helpStep9 > 2
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { staticClass: "tu-help-alert", attrs: { show: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h3", [
                                  _c("i", { staticClass: "fas fa-phone-alt" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xNeedHelpContactSupport")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xUsAndCanadaPhoneNumbers")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                { staticClass: "pr-0 pb-0", attrs: { cols: "auto" } },
                [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("xWhichDevicesConsumeFuel"))
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._l(_vm.selectedTankUtilFuelUses, function(type, index) {
            return _c(
              "b-row",
              {
                key: index,
                staticClass: "mb-2",
                attrs: { "align-v": "center" }
              },
              [
                _c(
                  "b-col",
                  {
                    attrs: {
                      "align-v": "center",
                      "data-test-hook":
                        "fuelUse-" +
                        _vm.removeExtraChars(_vm.$t(type.name)) +
                        "-Header"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(type.name)) + " ")]
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          pill: "",
                          variant: "outline-primary",
                          pressed: type.selected != null && type.selected,
                          "data-test-hook":
                            "fuelUse-" +
                            _vm.removeExtraChars(_vm.$t(type.name)) +
                            "-YesBtn"
                        },
                        on: {
                          click: function($event) {
                            return _vm.addTag(index, true)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xYes")) + " ")]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          pill: "",
                          variant: "outline-primary",
                          pressed: type.selected != null && !type.selected,
                          "data-test-hook":
                            "fuelUse-" +
                            _vm.removeExtraChars(_vm.$t(type.name)) +
                            "-NoBtn"
                        },
                        on: {
                          click: function($event) {
                            return _vm.addTag(index, false)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xNo")) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm.showTankUsageUnfinishedErrorMessage
            ? _c(
                "b-row",
                { staticClass: "mt-2 mb-2" },
                [
                  _c("b-col", [
                    _c(
                      "p",
                      {
                        staticClass: "colorRed",
                        attrs: {
                          "data-test-hook":
                            "tank-enrollment-step-9-unfinished-message"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xTankUsageUnfinishedErrorMessage")) +
                            " "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-3 pr-0" },
                [
                  _c("b-progress", {
                    attrs: {
                      value: 88.88,
                      variant: "primary",
                      "data-test-hook": "tank-enrollment-step-9-progress-bar"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-10",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: {
            shown: function($event) {
              _vm.activeModal = "specify-fuel-provider"
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-step-10-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-10-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button--gray",
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook":
                                  "tank-enrollment-step-10-close-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.areYouSure(
                                    "tank-enrollment-step-10"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook":
                          "tank-enrollment-step-10-previous-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.$bvModal.show("tank-enrollment-step-9"),
                            _vm.trackClick("tank-enrollment-step-10"),
                            _vm.helpStep9++
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled: _vm.finishingSetup,
                        "data-test-hook":
                          "tank-enrollment-step-10-finish-button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.finishSetup()
                        }
                      }
                    },
                    [
                      _vm.finishingSetup
                        ? _c("span", [_c("b-spinner")], 1)
                        : _c("span", [_vm._v(_vm._s(_vm.$t("xFinishSetup")))])
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.helpStep10 > 2
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { staticClass: "tu-help-alert", attrs: { show: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h3", [
                                  _c("i", { staticClass: "fas fa-phone-alt" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xNeedHelpContactSupport")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xUsAndCanadaPhoneNumbers")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "pb-0 pr-0", attrs: { cols: "auto" } },
                [
                  _c(
                    "h5",
                    {
                      staticClass: "mb-0 pb-0",
                      attrs: {
                        "data-test-hook":
                          "tank-enrollment-step-10-who-delivers-fuel-text"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xWhoDeliversYourFuel")) + " ")]
                  )
                ]
              )
            ],
            1
          ),
          _c("b-form-group", { attrs: { id: "fuelprovider-group" } }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.hideResults,
                    expression: "hideResults"
                  }
                ]
              },
              [
                _c(
                  "b-input-group",
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        autocomplete: "new-password",
                        "data-test-hook":
                          "tank-enrollment-step-10-fuel-dealer-search-field"
                      },
                      on: {
                        keyup: function($event) {
                          return _vm.typeAheadSearch()
                        }
                      },
                      model: {
                        value: _vm.freeformFuelProvider,
                        callback: function($$v) {
                          _vm.freeformFuelProvider = $$v
                        },
                        expression: "freeformFuelProvider"
                      }
                    }),
                    _c(
                      "b-input-group-append",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "my-0 py-0",
                            attrs: {
                              variant: "warning",
                              "data-test-hook":
                                "tank-enrollment-step-10-fuel-dealer-search-button"
                            },
                            on: {
                              click: function($event) {
                                !_vm.awaitingSearch
                                  ? _vm.typeAheadSearch()
                                  : null
                              }
                            }
                          },
                          [
                            _vm.awaitingSearch
                              ? _c("b-spinner", {
                                  staticStyle: {
                                    width: "1.5rem",
                                    height: "1.5rem"
                                  }
                                })
                              : _c("i", { staticClass: "fas fa-search" })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("b-row"),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _vm.fuelProviderResults
                          ? _c(
                              "b-list-group",
                              { staticClass: "suggested-input-list" },
                              _vm._l(_vm.fuelProviderResults, function(
                                provider,
                                index
                              ) {
                                return _c(
                                  "b-list-group-item",
                                  {
                                    key: index,
                                    staticClass: "hoverPointer",
                                    attrs: {
                                      "data-test-hook":
                                        "FuelProviderSuggestion-" +
                                        _vm.removeExtraChars(provider.name)
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.setFuelProvider(provider)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(provider.name) + " ")]
                                )
                              }),
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.fuelProviderName
                  ? _c(
                      "b-row",
                      { staticClass: "mt-3" },
                      [
                        _c(
                          "b-col",
                          {
                            attrs: {
                              "data-test-hook":
                                "tank-enrollment-step-10-selected-fuel-dealer"
                            }
                          },
                          [_vm._v(_vm._s(_vm.fuelProviderName))]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm.showFinishSetupError
            ? _c(
                "div",
                {
                  staticClass: "alert alert-danger alert--validation",
                  attrs: { show: _vm.showFinishSetupError }
                },
                [_vm._v(" " + _vm._s(_vm.finishSetupErrorMessage) + " ")]
              )
            : _vm._e(),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-3 pr-0" },
                [
                  _c("b-progress", {
                    attrs: {
                      value: 88.88,
                      variant: "primary",
                      "data-test-hook": "tank-enrollment-step-10-progress-bar"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-loading-subscription",
            size: "sm",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header": "",
            "hide-footer": ""
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c(
                "b-col",
                [
                  _c("b-spinner", {
                    staticStyle: { width: "10rem", height: "10rem" },
                    attrs: { variant: "warning", label: "Spinning" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-11",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-footer": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-step-11-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "far fa-badge-dollar colorOrange mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-11-header-icon"
                              }
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("xSubscribeToPremium")) + " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.loadingSubscriptionModal
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-spinner", {
                            staticStyle: { width: "10rem", height: "10rem" },
                            attrs: { variant: "warning", label: "Spinning" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "b-card",
                {
                  staticClass:
                    "paid-subs-info-card mb-0 sub-card mx-1 selected-sub-info-card",
                  attrs: {
                    "data-test-hook": "tank-enrollment-step-11-paid-card"
                  }
                },
                [
                  _c(
                    "b-card-title",
                    [
                      _c(
                        "b-row",
                        { staticClass: "text-center" },
                        [
                          _c("b-col", [
                            _c(
                              "h1",
                              {
                                staticClass: "mb-0 pb-0",
                                attrs: {
                                  "data-test-hook":
                                    "tank-enrollment-step-11-paid-card-title"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.tankSubscriptionName) + " "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-card-body",
                    { staticClass: "subs-info-card-body" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "mt-2 btn-subscription-radio disabled",
                                  attrs: {
                                    variant: "subscription-radio",
                                    pill: "",
                                    pressed: "",
                                    disabled: "",
                                    "data-test-hook":
                                      "tank-enrollment-step-11-select-button"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "sub-radio-dot align-middle"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa-solid fa-circle"
                                      })
                                    ]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          _vm.tankSubscriptionCost
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "sub-free-radio-text align-baseline"
                                    },
                                    [_vm._v("/" + _vm._s(_vm.$t("xYear")))]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-row", { staticClass: "pt-2" }, [
                        _c("div", [
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("xPremiumMonitoringLimits")))
                          ]),
                          _c("p", { staticClass: "pt-2" }, [
                            _c(
                              "strong",
                              {
                                attrs: {
                                  "data-test-hook":
                                    "tank-enrollment-step-11-included-features-text"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("xFeaturesIncluded")))]
                            )
                          ])
                        ])
                      ]),
                      _c("b-row", { staticClass: "feature-list" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("i", {
                              staticClass: "fa-regular fa-gas-pump fa-fw pr-4",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-11-fuel-monitor-feature-icon-1"
                              }
                            }),
                            _c("div", [
                              _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "tank-enrollment-step-11-fuel-monitor-feature-title-1"
                                  }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("xCurrentFuelLevel")))
                                  ])
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "supporting-text",
                                  attrs: {
                                    "data-test-hook":
                                      "tank-enrollment-step-11-fuel-monitor-feature-description-1"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xFuelLevelDescription")) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("i", {
                              staticClass:
                                "fa-regular fa-chart-line fa-fw pr-4",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-11-fuel-monitor-feature-icon-2"
                              }
                            }),
                            _c("div", [
                              _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "tank-enrollment-step-11-fuel-monitor-feature-title-2"
                                  }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("xFuelUsageHistory"))
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "supporting-text",
                                  attrs: {
                                    "data-test-hook":
                                      "tank-enrollment-step-11-fuel-monitor-feature-description-2"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xFuelUsageDescription")) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("i", {
                              staticClass: "fa-regular fa-warning fa-fw pr-4",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-11-fuel-monitor-feature-icon-3"
                              }
                            }),
                            _c("div", [
                              _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "tank-enrollment-step-11-fuel-monitor-feature-title-3"
                                  }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("xFuelLevelWarnings"))
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "supporting-text",
                                  attrs: {
                                    "data-test-hook":
                                      "tank-enrollment-step-11-fuel-monitor-feature-description-3"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xFuelLevelWarningsDescription")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("i", {
                              staticClass:
                                "fa-regular fa-battery-full fa-fw pr-4",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-11-fuel-monitor-feature-icon-4"
                              }
                            }),
                            _c("div", [
                              _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "tank-enrollment-step-11-fuel-monitor-feature-title-4"
                                  }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("xBatteryLevel"))
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "supporting-text",
                                  attrs: {
                                    "data-test-hook":
                                      "tank-enrollment-step-11-fuel-monitor-feature-description-4"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xBatteryLevelDescription")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
          _c(
            "b-row",
            { staticClass: "mt-3", attrs: { "no-gutters": "" } },
            [
              _c(
                "b-col",
                { staticClass: "float-left pl-2", attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-100",
                      attrs: {
                        variant: "warning",
                        "data-test-hook":
                          "tank-enrollment-step-11-confirm-plan-button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.show("tank-enrollment-step-12")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xContinue")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", { staticClass: "text-center" }, [
                _c("p", { staticClass: "legal-disclaimer" }, [
                  _vm._v(
                    "*" +
                      _vm._s(
                        _vm.$t("xPushNotificationsAreAvailableWithInstallation")
                      )
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-11-existing-apparatuses",
            size: "md",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            "hide-footer": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c("div", [
                    _c(
                      "h2",
                      {
                        staticClass: "modal-title",
                        attrs: {
                          "data-test-hook":
                            "tank-enrollment-step-11-existing-apparatuses-header"
                        }
                      },
                      [
                        _c("div", [
                          _c("i", {
                            staticClass: "far fa-badge-dollar colorOrange mr-1",
                            attrs: {
                              "data-test-hook":
                                "tank-enrollment-step-11-existing-apparatuses-header-icon"
                            }
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("xCongratulations") + "!") + " "
                          )
                        ])
                      ]
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.loadingSubscriptionModal
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-spinner", {
                            staticStyle: { width: "10rem", height: "10rem" },
                            attrs: { variant: "warning", label: "Spinning" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.existingSubscriptions.length > 0
                    ? _c(
                        "b-row",
                        {
                          staticClass: "mb-0 pb-0 current-plan",
                          attrs: { "align-h": "center", "no-gutters": "" }
                        },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "row mb-3 ml-3 mr-3",
                                  attrs: { "align-h": "around" }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-center plan-details-header",
                                      attrs: {
                                        "data-test-hook":
                                          "existing-plan-support-text"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("xCurrentPlanSupport", {
                                              0: _vm.existingSubscriptions[0]
                                                .product.name
                                            })
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "b-card",
                                {
                                  staticClass:
                                    "paid-subs-info-card mb-0 sub-card mx-1",
                                  attrs: {
                                    "body-class": "existing-info-card-body",
                                    "data-test-hook":
                                      "tank-enrollment-step-11-existing-apparatuses-paid-card",
                                    "header-class": "m-0 p-0"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "current-plan-header",
                                      attrs: {
                                        "data-test-hook": "existing-plan-header"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("xCurrentPlan")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-card-title",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "text-center" },
                                        [
                                          _c("b-col", [
                                            _c(
                                              "h1",
                                              {
                                                staticClass: "mb-0 pb-0",
                                                attrs: {
                                                  "data-test-hook":
                                                    "tank-enrollment-step-11-existing-apparatuses-paid-card-title"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm
                                                        .existingSubscriptions[0]
                                                        .product.name
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-card-body",
                                    { staticClass: "pt-0" },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "text-center mt-2" },
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "btn-subscription-radio",
                                                  attrs: {
                                                    variant:
                                                      "subscription-radio",
                                                    disabled: "",
                                                    pill: "",
                                                    pressed: true,
                                                    "data-test-hook":
                                                      "tank-enrollment-step-11-existing-apparatuses-select-yearly-button"
                                                  },
                                                  on: { click: () => {} }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "sub-radio-dot align-middle"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa-solid fa-circle"
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatCurrency"
                                                        )(
                                                          _vm
                                                            .existingSubscriptions[0]
                                                            .product.cost
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-bottom sub-premium-radio-duration"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "/" +
                                                          _vm._s(
                                                            _vm.$t("xYear")
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "subscriptionCardText" },
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c("b-col", [
                                                _c(
                                                  "strong",
                                                  {
                                                    attrs: {
                                                      "data-test-hook":
                                                        "tank-enrollment-step-11-existing-apparatuses-premium-monitoring-max-products-text"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "xPremiumMonitoringMaxProducts"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.existingSubscriptions[0]
                                              .apparatuses,
                                            function(apparatus, index) {
                                              return _c(
                                                "b-row",
                                                { key: index },
                                                [
                                                  _c(
                                                    "b-col",
                                                    { staticClass: "mt-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "d-flex"
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fas fa-fw fa-check-circle check-icon mr-2"
                                                          }),
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.apparatuses.filter(
                                                                  x =>
                                                                    x.apparatusId ===
                                                                    apparatus.apparatusId
                                                                )[0].name
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { staticClass: "mt-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "accordion",
                                                      staticStyle: {
                                                        width: "100%"
                                                      },
                                                      attrs: { role: "tablist" }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "accordion-wrapper"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-toggle",
                                                                  rawName:
                                                                    "v-b-toggle.collapse-1",
                                                                  modifiers: {
                                                                    "collapse-1": true
                                                                  }
                                                                }
                                                              ]
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row align-items-center no-gutters"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col d-flex"
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fas fa-fw fa-check-circle check-icon mr-2"
                                                                      }),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "p",
                                                                            {
                                                                              staticClass:
                                                                                "mb-0"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.tankName
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "p",
                                                                            {
                                                                              staticClass:
                                                                                "receives-features-text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xReceivesPremiumFeatures"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-auto"
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fas fa-chevron-down when-closed"
                                                                      }),
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fas fa-chevron-up when-open"
                                                                      })
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-collapse",
                                                            {
                                                              attrs: {
                                                                id: "collapse-1"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "features"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "premium"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "mb-1"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xFeaturesIncluded"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "feature"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa-regular fa-gas-pump"
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mb-2"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "p",
                                                                                {
                                                                                  attrs: {
                                                                                    "data-test-hook":
                                                                                      "tank-enrollment-step-11-fuel-monitor-feature-title-1"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xCurrentFuelLevel"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                {
                                                                                  staticClass:
                                                                                    "supporting-text",
                                                                                  attrs: {
                                                                                    "data-test-hook":
                                                                                      "tank-enrollment-step-11-fuel-monitor-feature-description-1"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "xFuelLevelDescription"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "feature"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa-regular fa-chart-line"
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mb-2"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "p",
                                                                                {
                                                                                  attrs: {
                                                                                    "data-test-hook":
                                                                                      "tank-enrollment-step-11-fuel-monitor-feature-title-1"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xFuelUsageHistory"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                {
                                                                                  staticClass:
                                                                                    "supporting-text",
                                                                                  attrs: {
                                                                                    "data-test-hook":
                                                                                      "tank-enrollment-step-11-fuel-monitor-feature-description-1"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "xFuelUsageDescription"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "feature"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa-regular fa-warning"
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mb-2"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "p",
                                                                                {
                                                                                  attrs: {
                                                                                    "data-test-hook":
                                                                                      "tank-enrollment-step-11-fuel-monitor-feature-title-1"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xFuelLevelWarnings"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                {
                                                                                  staticClass:
                                                                                    "supporting-text",
                                                                                  attrs: {
                                                                                    "data-test-hook":
                                                                                      "tank-enrollment-step-11-fuel-monitor-feature-description-1"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "xFuelLevelWarningsDescription"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "feature"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa-regular fa-battery-full"
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mb-2"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "p",
                                                                                {
                                                                                  attrs: {
                                                                                    "data-test-hook":
                                                                                      "tank-enrollment-step-11-fuel-monitor-feature-title-1"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xBatteryLevel"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                {
                                                                                  staticClass:
                                                                                    "supporting-text",
                                                                                  attrs: {
                                                                                    "data-test-hook":
                                                                                      "tank-enrollment-step-11-fuel-monitor-feature-description-1"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "xBatteryLevelDescription"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : !!this.basicHSB
                    ? _c(
                        "b-row",
                        {
                          staticClass: "mb-0 pb-0 current-plan",
                          attrs: { "align-h": "center", "no-gutters": "" }
                        },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "row mb-3 ml-3 mr-3",
                                  attrs: { "align-h": "around" }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-center plan-details-header",
                                      attrs: {
                                        "data-test-hook":
                                          "new-plan-with-upgrade-text"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("xFuelMonitorRequiresPlan")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "b-card",
                                {
                                  staticClass:
                                    "paid-subs-info-card mb-0 sub-card mx-1",
                                  attrs: {
                                    "body-class": "subs-info-card-body",
                                    "data-test-hook":
                                      "tank-enrollment-step-11-existing-apparatuses-new-plan-card",
                                    "header-class": "m-0 p-0"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "current-plan-header",
                                              attrs: {
                                                "data-test-hook":
                                                  "new-plan-header"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("xNewPlan")) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "b-card-title",
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "text-center" },
                                        [
                                          _c("b-col", [
                                            _c(
                                              "h1",
                                              {
                                                staticClass: "mb-0 pb-0",
                                                attrs: {
                                                  "data-test-hook":
                                                    "tank-enrollment-step-11-existing-apparatuses-paid-card-title"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.tankSubscriptionName
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "text-center mt-2" },
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "btn-subscription-radio",
                                              attrs: {
                                                variant: "subscription-radio",
                                                disabled: "",
                                                pill: "",
                                                pressed: true,
                                                "data-test-hook":
                                                  "tank-enrollment-step-11-existing-apparatuses-select-yearly-button"
                                              },
                                              on: { click: () => {} }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "sub-radio-dot align-middle"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa-solid fa-circle"
                                                  })
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatCurrency")(
                                                      _vm.tankSubscriptionCost
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-bottom sub-premium-radio-duration"
                                                },
                                                [
                                                  _vm._v(
                                                    "/" +
                                                      _vm._s(_vm.$t("xYear"))
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "subscriptionCardText" },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", [
                                            _c(
                                              "strong",
                                              {
                                                attrs: {
                                                  "data-test-hook":
                                                    "tank-enrollment-step-11-existing-apparatuses-premium-monitoring-max-products-text"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "xPremiumMonitoringMaxProducts"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", { staticClass: "mt-2" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "accordion mb-2",
                                                staticStyle: { width: "100%" },
                                                attrs: { role: "tablist" }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accordion-wrapper"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "b-toggle",
                                                            rawName:
                                                              "v-b-toggle.collapse-1",
                                                            modifiers: {
                                                              "collapse-1": true
                                                            }
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row align-items-center no-gutters"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col d-flex"
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-fw fa-check-circle check-icon mr-2"
                                                                }),
                                                                _c("div", [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "mb-0"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .basicHSB
                                                                            .name
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "receives-features-text"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "xReceivesPremiumFeatures"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-auto"
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-chevron-down when-closed"
                                                                }),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-chevron-up when-open"
                                                                })
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-collapse",
                                                      {
                                                        attrs: {
                                                          id: "collapse-1"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "features"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "basic"
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-1"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "xFeaturesIncludedInPlan"
                                                                            )
                                                                          ) +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "xBasic"
                                                                              )
                                                                            )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "feature"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "far fa-fw fa-clipboard-list"
                                                                    }),
                                                                    _c("div", [
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "basic-features"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "xFeaturesIncludedWiFiAnnuallySubscriptionFeature1"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "premium"
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-1"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "xFeaturesIncluded"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "feature"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "far fa-fw fa-bell"
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "premium-feature-text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xRealTimeAlerts"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "premium-feature-supporting-text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xRealTimeAlertsDesc"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "feature"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "far fa-fw fa-calendar"
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "premium-feature-text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xExerciseSettings"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "premium-feature-supporting-text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xExerciseSettingsDesc"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "feature"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "far fa-fw fa-arrows-spin"
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "premium-feature-text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xExerciseOnDemand"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "premium-feature-supporting-text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xExerciseOnDemandDesc"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "feature"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "far fa-fw fa-warning"
                                                                    }),
                                                                    _c("div", [
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "premium-feature-text"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "xDismissWarnings"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "premium-feature-supporting-text"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "xDismissWarningsDesc"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "accordion",
                                                staticStyle: { width: "100%" },
                                                attrs: { role: "tablist" }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accordion-wrapper"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "b-toggle",
                                                            rawName:
                                                              "v-b-toggle.collapse-2",
                                                            modifiers: {
                                                              "collapse-2": true
                                                            }
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row align-items-center no-gutters"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col d-flex"
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-fw fa-check-circle check-icon mr-2"
                                                                }),
                                                                _c("div", [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "mb-0"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.tankName
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "receives-features-text"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "xReceivesPremiumFeatures"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-auto"
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-chevron-down when-closed"
                                                                }),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-chevron-up when-open"
                                                                })
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-collapse",
                                                      {
                                                        attrs: {
                                                          id: "collapse-2"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "features"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "premium"
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-1"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "xFeaturesIncluded"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "feature"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa-regular fa-gas-pump"
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            attrs: {
                                                                              "data-test-hook":
                                                                                "tank-enrollment-step-11-fuel-monitor-feature-title-1"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "xCurrentFuelLevel"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "supporting-text",
                                                                            attrs: {
                                                                              "data-test-hook":
                                                                                "tank-enrollment-step-11-fuel-monitor-feature-description-1"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xFuelLevelDescription"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "feature"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa-regular fa-chart-line"
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            attrs: {
                                                                              "data-test-hook":
                                                                                "tank-enrollment-step-11-fuel-monitor-feature-title-2"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "xFuelUsageHistory"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "supporting-text",
                                                                            attrs: {
                                                                              "data-test-hook":
                                                                                "tank-enrollment-step-11-fuel-monitor-feature-description-2"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xFuelUsageDescription"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "feature"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa-regular fa-warning"
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            attrs: {
                                                                              "data-test-hook":
                                                                                "tank-enrollment-step-11-fuel-monitor-feature-title-3"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "xFuelLevelWarnings"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "supporting-text",
                                                                            attrs: {
                                                                              "data-test-hook":
                                                                                "tank-enrollment-step-11-fuel-monitor-feature-description-3"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xFuelLevelWarningsDescription"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "feature"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa-regular fa-battery-full"
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            attrs: {
                                                                              "data-test-hook":
                                                                                "tank-enrollment-step-11-fuel-monitor-feature-title-4"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "xBatteryLevel"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "supporting-text",
                                                                            attrs: {
                                                                              "data-test-hook":
                                                                                "tank-enrollment-step-11-fuel-monitor-feature-description-4"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xBatteryLevelDescription"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "float-left pl-2",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "w-100",
                              attrs: {
                                variant: "warning",
                                disabled: _vm.subscribingProduct,
                                "data-test-hook":
                                  "tank-enrollment-step-11-existing-apparatuses-confirm-plan-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onContinueFromSubscription()
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("xContinue")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c("b-col", { staticClass: "text-center" }, [
                        _c("p", { staticClass: "text-small legal-text" }, [
                          _vm._v(
                            "*" +
                              _vm._s(
                                _vm.$t(
                                  "xPushNotificationsAreAvailableWithInstallation"
                                )
                              )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-step-12",
            size: "lg",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: {
            shown: _vm.OnShowSubscriptionModal,
            hidden: _vm.OnHideSubscriptionModal
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-step-12-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-step-12-header-icon"
                              }
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("xAddAPaymentMethod")) + " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  !_vm.useExistingPaymentInfo || !_vm.existingPaymentInfo
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "warning",
                            disabled: _vm.updatingPaymentInfo,
                            block: "",
                            "data-test-hook":
                              "tank-enrollment-step-12-save-payment-info-button"
                          },
                          on: {
                            click: function($event) {
                              return _vm.updatePaymentInfo()
                            }
                          }
                        },
                        [
                          _vm.updatingPaymentInfo
                            ? _c("span", [_c("b-spinner")], 1)
                            : _c("span", [
                                _vm._v(_vm._s(_vm.$t("xSavePaymentInfo")))
                              ])
                        ]
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "warning",
                            disabled: _vm.subscribingProduct,
                            block: "",
                            "data-test-hook":
                              "tank-enrollment-step-12-confirm-subscription-button"
                          },
                          on: {
                            click: function($event) {
                              return _vm.subscribeProduct()
                            }
                          }
                        },
                        [
                          _vm.subscribingProduct
                            ? _c("span", [_c("b-spinner")], 1)
                            : _c("span", [
                                _vm._v(_vm._s(_vm.$t("xConfirmSubscription")))
                              ])
                        ]
                      )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.loadingSubscriptionModal
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-spinner", {
                            staticStyle: { width: "10rem", height: "10rem" },
                            attrs: { variant: "warning", label: "Spinning" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        show: _vm.showValidationBanner,
                        variant: "danger"
                      }
                    },
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("xExperiencedTheseErrors")))
                      ]),
                      _vm._l(_vm.errorMessages, function(message, index) {
                        return _c("ul", { key: index }, [
                          _c("li", { attrs: { id: index } }, [
                            _vm._v(" " + _vm._s(message) + " ")
                          ])
                        ])
                      })
                    ],
                    2
                  ),
                  _vm.existingPaymentInfo
                    ? _c(
                        "b-row",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-col",
                            [
                              _vm.useExistingPaymentInfo
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("p", [
                                                _c("strong", [
                                                  _c("i", {
                                                    staticClass:
                                                      "fal fa-credit-card"
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.existingPaymentInfo
                                                          .cardType
                                                      ) +
                                                      " - " +
                                                      _vm._s(
                                                        _vm.existingPaymentInfo
                                                          .cardSuffix
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("xExpires")) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.existingPaymentInfo
                                                        .expirationMonth +
                                                        "/" +
                                                        _vm.existingPaymentInfo
                                                          .expirationYear
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: {
                                    "data-test-hook":
                                      "tank-enrollment-step-12-use-existing-payment-info-checkbox"
                                  },
                                  model: {
                                    value: _vm.useExistingPaymentInfo,
                                    callback: function($$v) {
                                      _vm.useExistingPaymentInfo = $$v
                                    },
                                    expression: "useExistingPaymentInfo"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xUseExistingBillingInfo")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.existingPaymentInfo || !_vm.useExistingPaymentInfo
                    ? _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "h4",
                                  {
                                    attrs: {
                                      "data-test-hook":
                                        "tank-enrollment-step-12-enter-billing-info-text"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "xEnterYourCreditCardInformation"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("i", { staticClass: "asterisk-text" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xUpdatingPaymentWarning")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { id: "credit-card-number" } },
                                    [
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "################",
                                            expression: "'################'"
                                          }
                                        ],
                                        attrs: {
                                          type: "text",
                                          maxlength: "16",
                                          placeholder: _vm.$t(
                                            "xCreditCardNumber"
                                          ),
                                          state: _vm.validState(
                                            _vm.$v.creditCardNumber
                                          ),
                                          "aria-describedby":
                                            "card-number-invalid",
                                          "data-test-hook":
                                            "tank-enrollment-step-12-credit-card-number-field"
                                        },
                                        model: {
                                          value: _vm.$v.creditCardNumber.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.creditCardNumber,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.creditCardNumber.$model"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: { id: "card-number-invalid" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("xCCFieldValidation")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                { attrs: { lg: "6", md: "6", sm: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { id: "expiry-date-month" } },
                                    [
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "##",
                                            expression: "'##'"
                                          }
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t("xMm"),
                                          maxlength: "2",
                                          state: _vm.validState(
                                            _vm.$v.expirationMonth
                                          ),
                                          "aria-describedby":
                                            "expiry-month-invalid",
                                          "data-test-hook":
                                            "tank-enrollment-step-12-expiration-month-field"
                                        },
                                        model: {
                                          value: _vm.$v.expirationMonth.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.expirationMonth,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.expirationMonth.$model"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: { id: "expiry-month-invalid" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "xExpirationMonthValidation"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { lg: "6", md: "6", sm: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { id: "expiry-date-year" } },
                                    [
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "##",
                                            expression: "'##'"
                                          }
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t("xYy"),
                                          maxlength: "2",
                                          state: _vm.validState(
                                            _vm.$v.expirationYear
                                          ),
                                          "aria-describedby":
                                            "expiry-year-invalid",
                                          "data-test-hook":
                                            "tank-enrollment-step-12-expiration-year-field"
                                        },
                                        model: {
                                          value: _vm.$v.expirationYear.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.expirationYear,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression: "$v.expirationYear.$model"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: { id: "expiry-year-invalid" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "xExpirationYearValidation"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { id: "cvv" } },
                                    [
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "####",
                                            expression: "'####'"
                                          }
                                        ],
                                        attrs: {
                                          type: "text",
                                          maxlength: "4",
                                          placeholder: _vm.$t("xCvv"),
                                          state: _vm.validState(_vm.$v.cvv),
                                          "aria-describedby": "cvv-invalid",
                                          "data-test-hook":
                                            "tank-enrollment-step-12-cvv-field"
                                        },
                                        model: {
                                          value: _vm.$v.cvv.$model,
                                          callback: function($$v) {
                                            _vm.$set(_vm.$v.cvv, "$model", $$v)
                                          },
                                          expression: "$v.cvv.$model"
                                        }
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("b-img", {
                                                attrs: {
                                                  height: 20,
                                                  src: require("@/assets/images/3-digit-code.svg"),
                                                  "data-test-hook":
                                                    "tank-enrollment-step-12-cvv-image"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-invalid-feedback",
                                        { attrs: { id: "cvv-invalid" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xCVVValidation")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", { staticClass: "mt-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "subscription-cost" },
                                  [
                                    _c(
                                      "h4",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "tank-enrollment-step-12-subscription-cost-and-term"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                _vm.tankSubscriptionCost
                                              )
                                            ) +
                                            " " +
                                            _vm._s(_vm.$t("xBilledYearly")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c("b-row", [_c("b-col")], 1)
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          {
                            attrs: {
                              "data-test-hook":
                                "tank-enrollment-step-12-auto-renew-message-apply-to-all-subs"
                            }
                          },
                          [
                            _vm._v(
                              " *" +
                                _vm._s(
                                  _vm.$t("xUpdatingCCApplyActiveSubscriptions")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              "data-test-hook":
                                "tank-enrollment-step-12-auto-renew-message"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("xThisSubscriptionWillAutoRenew")
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { state: _vm.validState(_vm.$v.consent) },
                              model: {
                                value: _vm.$v.consent.$model,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.consent, "$model", $$v)
                                },
                                expression: "$v.consent.$model"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("xConsentCheckboxText")) +
                                  " "
                              )
                            ]
                          ),
                          _vm.consent == false
                            ? _c("p", { staticClass: "colorRed ml-4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xConsentNotCheckedError")) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showUpdatePaymentInfoError &&
                  _vm.updatePaymentInfoErrorMessage
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-danger alert--validation",
                          attrs: { show: _vm.showUpdatePaymentInfoError }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.updatePaymentInfoErrorMessage) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.showSubscribeProductError
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-danger alert--validation",
                          attrs: { show: _vm.showSubscribeProductError }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.subscribeProductErrorMessage) + " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-success",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "tank-enrollment-success-title"
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "colorOrange far fa-badge-dollar mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-success-title-icon"
                              }
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("xCongratulations") + "!") +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "warning",
                        "data-test-hook":
                          "tank-enrollment-success-go-to-dashboard-button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeEnrollmentModals()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xReturnToDashboard")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "text-center mt-2 mb-2" },
            [
              _c("b-col", [
                _c(
                  "p",
                  {
                    attrs: {
                      "data-test-hook": "tank-enrollment-success-body-header"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "xYourTankIsSubscribedForPremiumRemoteMonitoring"
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tank-enrollment-tank-reading-not-received",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook":
                                "tank-enrollment-tank-reading-not-received-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook":
                                  "tank-enrollment-tank-reading-not-received-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button--gray",
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook":
                                  "tank-enrollment-tank-reading-not-received-close-button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.areYouSure(
                                    "tank-enrollment-tank-reading-not-received"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                block: "",
                                variant: "warning",
                                "data-test-hook":
                                  "tank-enrollment-tank-reading-not-received-try-again-button"
                              },
                              on: {
                                click: function($event) {
                                  _vm.getTankReading(),
                                    _vm.$bvModal.show("tank-enrollment-step-6"),
                                    _vm.helpStep6++
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("xTryAgain")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                block: "",
                                variant: "outline-warning",
                                "data-test-hook":
                                  "tank-enrollment-tank-reading-not-received-confirm-install-button"
                              },
                              on: {
                                click: function($event) {
                                  _vm.$bvModal.show("tank-enrollment-step-5"),
                                    _vm.helpStep5++
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("xConfirmInstallation")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.helpReadingNotReceived > 2
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        { staticClass: "tu-help-alert", attrs: { show: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h3", [
                                  _c("i", { staticClass: "fas fa-phone-alt" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xNeedHelpContactSupport")
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xUsAndCanadaPhoneNumbers")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c("b-col", [
                _c(
                  "h5",
                  {
                    staticClass: "text-center",
                    attrs: {
                      "data-test-hook":
                        "tank-enrollment-tank-reading-not-received-no-reading-text"
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("xTankReadingNotReceived")) + " "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 mb-2", attrs: { "align-h": "center" } },
            [
              _c("b-col", { attrs: { cols: "auto" } }, [
                _c("i", { staticClass: "colorOrange far fa-sad-tear fa-2x" })
              ])
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "mt-4 ml-1 mr-1 check-cellular",
              attrs: { "align-h": "center" }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("xPleaseCheckYourCellularConnection")) + " "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "wifi-tank-setup",
            size: "md",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "wifi-tank-setup-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "colorOrange fas fa-plus mr-1",
                              attrs: {
                                "data-test-hook": "wifi-tank-setup-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAddATank")) + " ")
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button--gray",
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook": "wifi-tank-setup-close-button"
                              },
                              on: {
                                click: function($event) {
                                  _vm.$bvModal.hide("wifi-tank-setup"),
                                    _vm.resetInfo()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "warning",
                        block: "",
                        "data-test-hook": "wifi-tank-setup-ok-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.$bvModal.hide("wifi-tank-setup"), _vm.resetInfo()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xOK")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("h4", [_vm._v(_vm._s(_vm.$t("xWifiTankSetup")))])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", {
                attrs: { "data-test-hook": "wifi-tank-setup-message" },
                domProps: { innerHTML: _vm._s(_vm.$t("xWifiTankSetupMessage")) }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "confirm-close-wizard",
            size: "md",
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: {
                              "data-test-hook": "confirm-close-wizard-header"
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fas fa-exclamation-triangle colorOrange mr-1",
                              attrs: {
                                "data-test-hook":
                                  "confirm-close-wizard-header-icon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAreYouSure")) + " ")
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "confirm-close-wizard-cancel-button"
                      },
                      on: {
                        click: function($event) {
                          _vm.$bvModal.hide("confirm-close-wizard"),
                            _vm.$bvModal.show(_vm.currentWizardWindow)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xNoCancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        "data-test-hook": "confirm-close-wizard-confirm-button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeEnrollmentModals()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xYesImSure")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "mt-1", staticStyle: { "font-size": "1rem" } },
            [
              _c(
                "b-col",
                {
                  attrs: {
                    "data-test-hook": "confirm-close-wizard-are-you-sure-text"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("xAreYouSureYouWantToExitBeforeFinishing")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c("b-row", {
            staticClass: "mt-2 checkbox-row",
            attrs: { "no-gutters": "" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }