<template>
  <div>
    <b-list-group flush>
      <b-list-group-item
        class="py-3 hoverPointer "
        v-if="hasAccountMessages() && documentsStatementsPageEnabled(currentAppConfig)"
        :class="getPreviewClass(messages.accountMessages[0])"
        @click="viewMessageDetails(messages.accountMessages[0].category)"
        data-test-hook="msgPreviewAcctMsgsBtn"
      >
        <b-row align-v="center" no-gutters>
          <b-col>
            <b-row class="preview-header">
              <b-col>
                <i class="fas fa-user-circle" data-test-hook="msgPreviewAcctMsgsBtnIcon" />
                <span class="pl-1" data-test-hook="msgPreviewAcctMsgsBtnText">{{ $t('xAccountMessages') }}</span>
                <i
                  class="fa fa-circle colorOrange text-small ml-1"
                  :class="showNewMessages(messages.accountMessages[0])"
                  data-test-hook="msgPreviewUnreadAcctMsgsCircle"
                ></i>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pt-2">
                <span data-test-hook="msgPreviewAcctMsgsCountValue">{{ messages.accountMessages.length }}</span>
                <span v-if="messages.accountMessages.length > 1" data-test-hook="msgPreviewAcctMsgsText">Messages</span>
                <span v-else data-test-hook="msgPreviewAcctMsgText">Message</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item v-else-if="documentsStatementsPageEnabled(currentAppConfig)" class="pl-2 pr-0">
        <b-row align-v="center" class="pl-3">
          <b-col>
            <b-row class="preview-header">
              <b-col class="no-unread-messages-color">
                <i class="fas fa-user-circle preview-header-label" data-test-hook="msgPreviewAcctMsgsIcon" />
                <span class="pl-1 preview-header-label" data-test-hook="msgPreviewAcctMsgsText">{{
                  $t('xAccountMessages')
                }}</span>
              </b-col>
            </b-row>
            <b-row class="mt-2 preview-header">
              <b-col>
                <p>
                  <i data-test-hook="msgPreviewAcctMsgsNoUnreadMsgsText">{{ $t('xNoUnreadMessages') }}</i>
                </p>
                <b-button
                  variant="link"
                  class="py-0 mt-2"
                  :to="{ name: 'accountSettings', params: { statementTabActive: true } }"
                  data-test-hook="msgPreviewAcctMsgsViewStmtsBtn"
                >
                  <span class="preview-header-label" data-test-hook="msgPreviewAcctMsgsViewStmtsBtnText">{{
                    $t('xViewStatements')
                  }}</span>
                  <i class="fas fa-arrow-right ml-1" data-test-hook="msgPreviewAcctMsgsViewStmtsBtnIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item
        class="py-3 hoverPointer"
        v-if="hasAppUpdateMessages()"
        :class="getPreviewClass(messages.appUpdates[0])"
        @click="viewMessageDetails(messages.appUpdates[0].category)"
        data-test-hook="msgPreviewAppUpdsBtn"
      >
        <b-row align-v="center" no-gutters>
          <b-col>
            <b-row class="preview-header">
              <b-col>
                <i class="fas fa-newspaper" data-test-hook="msgPreviewAppUpdsBtnIcon" />
                <span data-test-hook="msgPreviewAppUpdsBtnText">{{ $t('xInbox') }}</span>
                <i
                  class="fa fa-circle colorOrange text-small ml-1"
                  :class="showNewMessages(messages.appUpdates[0])"
                  data-test-hook="msgPreviewAppUpdsCircle"
                ></i>
                <span v-if="messages.appUpdates[0].createdDate"> </span>
                <span v-else>
                  <i>{{ $t('xNoUnreadMessages') }}</i>
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pt-2">
                <span data-test-hook="msgPreviewAppUpdsCountValue">{{ messages.appUpdates.length }}</span>
                <span v-if="messages.appUpdates.length > 1" data-test-hook="msgPreviewAppUpdsText">Messages</span>
                <span v-else data-test-hook="msgPreviewAppUpdText">Message</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item v-else class="py-3">
        <b-row align-v="center" no-gutters>
          <b-col>
            <b-row class="preview-header">
              <b-col class="no-unread-messages-color">
                <i class="fas fa-newspaper preview-header-label" data-test-hook="msgPreviewAppUpdsIcon" />
                <span class="pl-1 preview-header-label" data-test-hook="msgPreviewAppUpdsText">{{ $t('xInbox') }}</span>
              </b-col>
            </b-row>
            <b-row class="mt-2 preview-header">
              <b-col>
                <p data-test-hook="msgPreviewNoAppUpdsText">
                  <i>{{ $t('xNoUnreadMessages') }}</i>
                </p>
                <b-button
                  variant="link"
                  class="py-0 mt-2"
                  href="https://support.generac.com/mobilelink"
                  target="_blank"
                  data-test-hook="msgPreviewSupportCenterBtn"
                >
                  <span class="preview-header-label" data-test-hook="msgPreviewSupportCenterBtnText">{{
                    $t('xSupportCenter')
                  }}</span>
                  <i class="fas fa-arrow-right ml-1" data-test-hook="msgPreviewSupportCenterBtnIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <DeleteMessagesModal v-if="messagesToDelete" :messages="messagesToDelete" @clearMessages="clearMessageDetails" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'
import DeleteMessagesModal from '@/components/MessageCenter/DeleteMessagesModal'

export default {
  components: { DeleteMessagesModal },
  data() {
    return {
      accountMessagesSelected: false,
      appUpdateMessagesSelected: false,
      messagesToDelete: null
    }
  },
  computed: {
    ...mapState({
      messages: state => state.account.messages,
      currentAppConfig: state => state.application.currentAppConfig
    })
  },
  methods: {
    clearMessageDetails() {
      this.$emit('messageDetails', null)
    },
    getPreviewClass(message) {
      let previewClass = ''
      previewClass += !message.read ? 'bold-text' : 'read-preview'
      if (message.category == Constants.AccountMessagesMessageCategory) {
        previewClass += this.accountMessagesSelected ? ' selected' : ''
      } else if (message.category == Constants.AppUpdatesMessageCategory) {
        previewClass += this.appUpdateMessagesSelected ? ' selected' : ''
      }
      return previewClass
    },
    showNewMessages(message) {
      let newMessages = ''
      newMessages += !message.read ? 'd-inline-block' : 'd-none'
      return newMessages
    },
    openDeleteConfirmationModal(value) {
      if (value && value[0]) {
        this.viewMessageDetails(value[0].category)
        this.messagesToDelete = value
        this.$bvModal.show('delete-all-messages-modal')
      }
    },
    hasAccountMessages() {
      return this.messages && this.messages.accountMessages && this.messages.accountMessages.length > 0
    },
    hasAppUpdateMessages() {
      return this.messages && this.messages.appUpdates && this.messages.appUpdates.length > 0
    },
    viewMessageDetails(messageCategory) {
      if (messageCategory == Constants.AccountMessagesMessageCategory) {
        this.appUpdateMessagesSelected = false
        this.accountMessagesSelected = true
        this.$emit('messageDetails', this.messages.accountMessages)
      } else if (messageCategory == Constants.AppUpdatesMessageCategory) {
        this.accountMessagesSelected = false
        this.appUpdateMessagesSelected = true
        this.$emit('messageDetails', this.messages.appUpdates)
      }
      this.messagesToMarkAsRead = []
      if (this.appUpdateMessagesSelected) {
        this.messages.appUpdates.forEach(element => {
          if (!element.read) this.messagesToMarkAsRead.push(element.id)
        })
      } else if (this.accountMessagesSelected) {
        this.messages.accountMessages.forEach(element => {
          if (!element.read) this.messagesToMarkAsRead.push(element.id)
        })
      }
      if (this.messagesToMarkAsRead && this.messagesToMarkAsRead.length > 0) {
        this.$store
          .dispatch('markMessagesAsRead', this.messagesToMarkAsRead)
          .then(response => {
            this.messagesToMarkAsRead = null
          })
          .catch(error => {})
      }
    }
  },
  watch: {
    messages: function(newVal, oldVal) {
      if (this.appUpdateMessagesSelected) {
        this.$emit('messageDetails', newVal && newVal.appUpdates ? newVal.appUpdates : null)
      } else if (this.accountMessagesSelected) {
        this.$emit('messageDetails', newVal && newVal.accountMessages ? newVal.accountMessages : null)
      }
    }
  },
  mounted() {
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
    if (!this.messages) this.$store.dispatch('getMessages')
  }
}
</script>
<style scoped>
.list-group-item:hover {
  background: var(--blue-600);
  color: var(--white);
}

.list-group-item:hover .preview-header {
  color: var(--white);
}
.list-group-item:hover .preview-header-label {
  color: var(--white);
}
.bold-text {
  font-weight: bold;
}

.read-preview {
  border-left-width: 5px !important;
  border-left-color: var(--white);
}
.preview-header {
  font-weight: bold;
  text-transform: uppercase;
  color: var(--carbon-600);
}

.fa-circle {
  color: var(--blue-700);
}
.no-unread-messages-color {
  color: var(--carbon-600);
}
.selected {
  border-left-color: var(--blue-700) !important;
  color: var(--blue-700);
}
</style>
