<template>
  <div>
    <div v-if="isLoading" class="d-flex justify-content-center mb-3 pt-4">
      <b-spinner style="width: 5rem; height: 5rem" variant="warning" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div class="ml-3 mr-3" rounded="lg" id="overlay">
        <div class="row no-gutters mb-2 mt-4">
          <div class="col">
            <h1 class="mb-0 pb-0" data-test-hook="pageTitle">
              <i class="far fa-user-circle pr-1" data-test-hook="titleIcon" />
              <span data-test-hook="account-info-title-account-settings">{{ $t('xAccountInformation') }}</span>
            </h1>
          </div>
        </div>
        <div class="card" header-bg-variant="white">
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-auto mb-1">
                <i class="far fa-user"></i>
                <strong class="ml-2" data-test-hook="account-holder-name-address-title-account-settings">
                  {{ $t('xAccountHolder') }}
                </strong>
              </div>
              <div class="col-auto">
                <popper trigger="hover" :options="{ placement: 'right' }">
                  <div class="popper">
                    {{ $t('xPrimaryBillingContactAndAddressInfo') }}
                  </div>
                  <i class="far fa-question-circle" slot="reference"></i>
                </popper>
              </div>
            </div>
            <div class="row">
              <div v-if="!accountInfo" class="d-flex justify-content-center mb-3 pt-1">
                <span
                  class="spinner-border spinner-border-xl text-warning"
                  style="width: 5rem; height: 5rem"
                  data-test-hook="LoadingSpinner"
                  label="Spinning"
                ></span>
              </div>
              <div v-if="accountInfo" class="col">
                <p data-test-hook="account-holder-name-account-settings">
                  {{ accountInfo.ownerFirstName + ' ' + accountInfo.ownerLastName }}
                </p>
                <p
                  v-if="accountInfo.ownerAddress.line1"
                  data-test-hook="account-holder-address-line-1-account-settings"
                >
                  {{ accountInfo.ownerAddress.line1 }}
                </p>
                <p
                  v-if="accountInfo.ownerAddress.line2"
                  data-test-hook="account-holder-address-line-2-account-settings"
                >
                  {{ accountInfo.ownerAddress.line2 }}
                </p>
                <p data-test-hook="account-holder-address-city-state-zip-account-settings">
                  {{
                    accountInfo.ownerAddress.city +
                      ', ' +
                      accountInfo.ownerAddress.region +
                      ' ' +
                      accountInfo.ownerAddress.postalCode
                  }}
                </p>
                <p data-test-hook="account-holder-phone-number-account-settings">
                  {{ accountInfo.ownerPhoneNumber }}
                </p>
              </div>
            </div>
            <div class="row pb-4">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-outline-warning mt-3"
                  @click="openEditAccountModal()"
                  :title="$t('xUpdateAccountInfo')"
                  data-test-hook="update-account-info-button-account-settings"
                >
                  {{ $t('xUpdateAccountInfo') }}
                </button>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-auto mb-1">
                <i class="far fa-lock colorOrange"></i>
                <strong class="ml-2" data-test-hook="account-holder-login-title-account-settings">
                  {{ $t('xAccountLogin') }}
                </strong>
              </div>
              <div class="col-auto">
                <popper trigger="hover" :options="{ placement: 'right' }">
                  <div class="popper">
                    {{ $t('xEmailUsedToLoginAndRegisterMessage') }}
                  </div>
                  <i slot="reference" class="far fa-question-circle "></i>
                </popper>
              </div>
            </div>
            <div class="row">
              <div class="col-auto">
                <p v-if="accountProfile.identityEmail" data-test-hook="account-holder-login-email-account-settings">
                  {{ accountProfile.identityEmail }}
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-outline-warning mt-3"
                  @click="updateEmail(accountProfile.identityEmail)"
                  :title="$t('xUpdateAccountEmail')"
                  data-test-hook="update-account-email-button-account-settings"
                >
                  {{ $t('xUpdateAccountEmail') }}
                  <i class="fas fa-external-link" />
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <hr class="mx-0 px-0" />
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-outline-danger rounded-pill mt-4"
                  @click="deleteAccount()"
                  :disabled="isImpersonating"
                  :title="deleteAccountTitle()"
                  data-test-hook="delete-account-button-account-settings"
                >
                  <i class="fas fa-trash-alt colorOrange"></i>
                  {{ $t('xDeleteAccount') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal modalId="unable-to-delete-account-modal" size="modal-lg">
        <ModalHeader :title="$t('xUnableToDeleteAccount')" icon="fa-regular fa-trash-can">
          <button
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModal('unable-to-delete-account-modal')"
            data-test-hook="unable-to-delete-account-x-button"
          >
            <i class="fas fa-times" />
          </button>
        </ModalHeader>
        <ModalBody>
          <p v-html="$t('xVppDeleteAccountModalBody1')" data-test-hook="modalBodyText1" />
          <p class="mt-3" data-test-hook="modalBodyText2">
            {{ $t('xVppDeleteAccountModalBody2') }}
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            class="btn btn-primary"
            @click="closeModal('unable-to-delete-account-modal')"
            data-test-hook="modalDismissButton"
          >
            {{ $t('xDismiss') }}
          </button>
        </ModalFooter>
      </Modal>

      <EditAccountInfoModal :shouldSetModal="shouldSetModal" @shouldSetModal="setShouldSetModal()" />
      <DeleteAccountModal
        :deleteAccountModalInfo="deleteAccountModalInfo"
        @showConfirmDeleteAccountModal="showConfirmDeleteAccountModal"
      />
      <ConfirmDeleteAccountModal :confirmDeleteAccountModalInfo="confirmDeleteAccountModalInfo" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import EditAccountInfoModal from '@/components/AccountSettings/EditAccountInfoModal'
import DeleteAccountModal from '@/components/AccountSettings/DeleteAccountModal'
import ConfirmDeleteAccountModal from '@/components/AccountSettings/ConfirmDeleteAccountModal'

const monthInRange = (value, vm) => value >= 1 && value <= 12

export default {
  components: { EditAccountInfoModal, DeleteAccountModal, ConfirmDeleteAccountModal },
  mixins: [validationMixin],
  data() {
    return {
      confirmDeleteAccountModalInfo: {},
      deleteAccountModalInfo: {},
      shouldSetModal: false,
      userInfo: {},
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      user: state => state.account.user,
      accountInfo: state => state.account.accountInfo,
      isImpersonating: state => state.auth.isImpersonating
    })
  },
  methods: {
    setShouldSetModal() {
      this.shouldSetModal = !this.shouldSetModal
    },
    openEditAccountModal() {
      this.shouldSetModal = true
      this.openModal('EditAccountInfoModal')
    },
    deleteAccount() {
      if (this.accountProfile && this.accountProfile.enrolledInVpp) {
        this.openModal('unable-to-delete-account-modal')
      } else {
        this.deleteAccountModalInfo = {
          orgId: this.accountProfile.organizationId
        }
        this.openModal('DeleteAccountModal')
      }
    },
    closeDeleteAccountModal() {
      document.getElementById('DeleteAccountModal').style.display = 'none'
    },
    displayConfirmDeleteAccountModal(orgId) {
      this.confirmDeleteAccountModalInfo = {
        orgId: orgId
      }
      document.getElementById('ConfirmDeleteAccountModal').style.display = 'block'
    },
    showConfirmDeleteAccountModal(info) {
      this.closeDeleteAccountModal()
      this.displayConfirmDeleteAccountModal(info.orgId)
    },
    deleteAccountTitle() {
      return this.isImpersonating ? this.$t('xNotPermittedWhileImpersonating') : ''
    }
  },
  watch: {
    accountProfile: function(newVal, oldVal) {
      if (newVal) {
        this.accountData = _.cloneDeep(this.accountProfile)
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('getAccountInfo')
    if (this.accountProfile) {
      this.accountData = _.cloneDeep(this.accountProfile)
    } else {
      await this.$store.dispatch('getAccountProfile')
    }
    this.isLoading = false
  }
}
</script>
<style scoped>
*.bold-text {
  font-weight: bold;
}
*.btn-outline-danger {
  border: 2px solid;
}
</style>
