<template>
  <div>
    <Modal modalId="addMaintenanceModal" size="modal-xl">
      <ModalHeader :title="getTitle()" icon="fas fa-calendar-plus">
        <button
          class="btn btn-icon-button--gray"
          @click="closeThisModal()"
          data-test-hook="x-button-add-maintenance-modal"
        >
          <i class="fas fa-times" />
        </button>
      </ModalHeader>
      <ModalBody>
        <div class="row mb-2">
          <div class="col">
            <strong>{{ $t('xMaintenanceType') }} </strong>
            <i style="color: grey;">({{ $t('xSelectAllThatApply') }})</i>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button
              class="btn mx-2 mb-2 rounded-pill"
              type="button"
              v-for="(type, index) in selectedMaintenanceTypes"
              :key="index"
              :class="getVariant(type.selected)"
              @click="addTag(type.name, index)"
              :data-test-hook="'add-tag-button-add-maintenance-modal' + index"
            >
              {{ type.name }}
              <i v-if="type.selected" class="fas fa-check fa-md" />
            </button>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col" style="font-size:1.15em">
            {{ $t('xOther') }}
            <div class="input-group mt-2 mb-3">
              <input
                type="text"
                :placeholder="$t('xEnterMaintenanceType')"
                class="form-control"
                pattern="[^A-Za-z0-9 ]+"
                maxlength="30"
                v-model="otherMaintenanceType"
                v-on:keyup.enter="addOtherMaintenanceType()"
                @keypress="validTagChars($event)"
              />
              <div class="input-group-append">
                <div class="input-group-text hoverPointer add-button" @click="addOtherMaintenanceType()">
                  {{ $t('xAdd') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg col-12">
            <div class="row mb-2" style="width:175px">
              <div class="col" style="font-size:1.15em">
                {{ $t('xDateCompleted') }}
              </div>
            </div>
            <input type="date" class="form-control mt-1 btn-outline-warning" v-model="dateCompleted" />
          </div>
          <div class="col-lg col-12" style="font-size:1.15em">
            {{ $t('xTotalCost') }}
            <div class="input-group mt-2 mb-3">
              <div class="input-group-text">$</div>
              <input
                v-model="totalCost"
                type="number"
                min="0.00"
                max="99999.99"
                step="0.01"
                placeholder="0.00"
                class="form-control"
                v-on:keyup.enter="money($event)"
              />
            </div>
          </div>
          <div class="col-lg col-12" style="font-size:1.15em">
            {{ $t('xRunHours') }}
            <div class="input-group mt-2 mb-3">
              <input
                v-model="runHours"
                type="text"
                :placeholder="$t('xRunHours')"
                class="form-control"
                v-mask="'######'"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="comments">{{ $t('xNotes') }}</label>
            <textarea
              id="comments"
              :placeholder="$t('xEnterComments')"
              wrap="soft"
              class="form-control"
              maxlength="500"
              style="resize:none; overflow-y:scroll;"
              v-model="comments"
              rows="3"
              max-rows="8"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeThisModal()"
          :disabled="disableCancel"
          data-test-hook="cancel-button-add-maintenance-modal"
        >
          {{ $t('xCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="saveMaintenance()"
          :disabled="!canAddRecord() || savingRecord"
          data-test-hook="save-button-add-maintenance-modal"
        >
          {{ $t('xSave') }}
          <div class="spinner-border text-muted ml-2" v-if="savingRecord" />
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapAction } from 'vuex'
import * as Constants from '@/constants'

export default {
  props: ['editedMaintenanceRecord', 'disableCancel', 'editingRecord', 'sendClearMaintenance', 'apparatus'],
  data() {
    return {
      selectedMaintenanceTypes: null,
      otherMaintenanceType: null,
      dateCompleted: null,
      totalCost: null,
      runHours: null,
      comments: null,
      maintenanceTagsToAdd: [],
      savingRecord: false,
      recordToEdit: null,
      pageNumber: 1,
      pageSize: 10,
      saveEditedRecord: false
    }
  },
  computed: {
    portableGenApparatusType() {
      return Constants.PortableGeneratorApparatusType
    },
    hsbApparatusType() {
      return Constants.HsbApparatusType
    }
  },
  methods: {
    saveEditedMaintenanceRecord() {
      let editedRecord = {
        apparatusMaintenance: {
          maintenanceOccurredDateUtc: this.dateCompleted,
          apparatusRunHours: this.runHours,
          totalCost: this.totalCost,
          ownerAdditionalNotes: this.comments
        },
        tags: this.maintenanceTagsToAdd
      }
      this.$store
        .dispatch('editApparatusMaintenanceHistory', {
          apparatusId: this.$route.params.apparatusId,
          serviceHistoryId: this.recordToEditId,
          editedRecord: editedRecord
        })
        .then(response => {
          this.$appInsights.trackEvent({
            name: 'edited-maintenance-record',
            properties: {
              page: 'maintenance',
              apparatusId: this.$route.params.apparatusId,
              recordId: this.recordToEditId
            }
          })
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xEditMaintenanceSuccess'),
            variant: 'success'
          })
          this.$emit('maintenanceHistoryChanged')
          this.closeThisModal('addMaintenanceModal')
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToEditMaintenanceRecord'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.savingRecord = false
        })
    },
    money(evt) {
      evt = evt ? evt : window.event
      let charCode = evt.which ? evt.which : evt.keyCode
      let prevent = false
      if (this.totalCost) {
        let numbers = this.totalCost.split('.')
        if (
          numbers[0] &&
          numbers[0].length == 5 &&
          numbers[1] &&
          numbers[1].length == 2 &&
          charCode != 8 &&
          charCode != 46
        )
          prevent = true
        else if (numbers[0] && numbers[0].length == 5 && !numbers[1] && (charCode == 110 || charCode == 190))
          prevent = false
        else if (numbers[0] && numbers[0].length == 5 && numbers[1] && numbers[1].length < 2) prevent = false
        if (numbers[0] && numbers[0] > 99999) {
          numbers[0] = numbers[0].slice(0, 5)
        }
        if (numbers[1] && numbers[1] > 99) {
          numbers[1] = numbers[1].slice(0, 2)
        }
        if (numbers[0]) {
          this.totalCost = numbers[0]
        }
        if (numbers[1]) this.totalCost += '.' + numbers[1]
      }

      if (prevent) {
        evt.preventDefault()
      } else return true
    },
    dateCompletedLabel() {
      if (this.dateCompleted) {
        return this.$moment.utc(this.dateCompleted).format(this.$t('xShortDate'))
      } else return this.$t('xShortDate')
    },
    validTagChars(evt) {
      evt = evt ? evt : window.event
      let charCode = evt.which ? evt.which : evt.keyCode
      if (
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 97 && charCode <= 122) ||
        (charCode >= 65 && charCode <= 90) ||
        charCode == 32
      ) {
        return true
      } else evt.preventDefault()
    },
    addOtherMaintenanceType() {
      if (this.otherMaintenanceType && !this.otherMaintenanceType.match(/^ *$/)) {
        this.selectedMaintenanceTypes.push({ name: this.otherMaintenanceType, selected: true })
        this.maintenanceTagsToAdd.push(this.otherMaintenanceType)
        this.otherMaintenanceType = null
      } else {
        this.$store.dispatch('displayToast', {
          title: this.$t('xError'),
          message: this.$t('xStringsCannotBeEmpty'),
          variant: 'danger'
        })
      }
    },
    addTag(name, index) {
      this.selectedMaintenanceTypes[index].selected = !this.selectedMaintenanceTypes[index].selected
      if (this.maintenanceTagsToAdd.includes(name)) {
        this.maintenanceTagsToAdd.splice(this.maintenanceTagsToAdd.indexOf(name), 1)
      } else this.maintenanceTagsToAdd.push(name)
    },
    getVariant(selected) {
      if (selected) return 'btn-warning'
      else return 'btn-outline-warning'
    },
    canAddRecord() {
      if (this.dateCompleted && this.maintenanceTagsToAdd && this.maintenanceTagsToAdd.length > 0) {
        return true
      } else return false
    },
    saveMaintenance() {
      this.savingRecord = true
      if (this.editingRecord) {
        this.saveEditedMaintenanceRecord()
      } else if (this.sendClearMaintenance) {
        this.clearMaintenanceAndSaveMaintenanceRecord()
      } else {
        let recordToAdd = {
          apparatusMaintenance: {
            maintenanceOccurredDateUtc: this.dateCompleted + ' ' + this.$moment.utc().format('HH:mm:ss'),
            apparatusRunHours: this.runHours,
            totalCost: this.totalCost,
            ownerAdditionalNotes: this.comments
          },
          tags: this.maintenanceTagsToAdd
        }
        this.$store
          .dispatch('CreateApparatusMaintenanceHistory', {
            apparatusId: this.$route.params.apparatusId,
            newMaintenanceRecord: recordToAdd
          })
          .then(response => {
            this.$emit('maintenanceHistoryChanged')
            this.closeThisModal('addMaintenanceModal')
          })
          .catch(error => {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xFailedToAddMaintenanceRecord'),
              variant: 'danger'
            })
          })
          .finally(() => {
            this.savingRecord = false
          })
      }
    },
    clearMaintenanceAndSaveMaintenanceRecord() {
      this.savingRecord = true
      this.$store
        .dispatch('clearMaintenance', { apparatusId: this.$route.params.apparatusId })
        .then(response => {
          let recordToAdd = {
            apparatusMaintenance: {
              maintenanceOccurredDateUtc: this.dateCompleted,
              apparatusRunHours: this.runHours,
              totalCost: this.totalCost,
              ownerAdditionalNotes: this.comments
            },
            tags: this.maintenanceTagsToAdd
          }
          this.$store
            .dispatch('CreateApparatusMaintenanceHistory', {
              apparatusId: this.$route.params.apparatusId,
              newMaintenanceRecord: recordToAdd
            })
            .then(response => {
              this.savingRecord = false
              this.$store.dispatch('displayToast', {
                title: this.$t('xSuccess'),
                message: this.$t('xMaintenanceAlertHasBeenCleared'),
                variant: 'success'
              })
              this.closeThisModal('addMaintenanceModal')
            })
            .catch(error => {
              this.savingRecord = false
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xFailedToAddMaintenanceRecord'),
                variant: 'danger'
              })
            })
        })
        .catch(error => {
          this.savingRecord = false
          if (error.response.data.detail) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: error.response.data.detail,
              variant: 'danger'
            })
          } else {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xUnableToClearMaintenance'),
              variant: 'danger'
            })
          }
        })
    },
    closeThisModal() {
      this.closeModal('addMaintenanceModal')
      this.resetModal()
    },
    resetModal() {
      this.maintenanceTagsToAdd = []
      this.setMaintenanceTypes()
      this.selectedMaintenanceTypes.forEach(element => {
        element.selected = false
      })
      this.otherMaintenanceType = null
      this.dateCompleted = null
      this.totalCost = null
      this.runHours = null
      this.comments = null
      this.recordToEditId = null
    },
    setMaintenanceTypes() {
      this.selectedMaintenanceTypes = null
      if (this.apparatus.apparatusClassification == Constants.HsbApparatusType) {
        this.selectedMaintenanceTypes = _.clone(Constants.HsbMaintenanceTypes)
      } else if (this.apparatus.apparatusClassification == Constants.PortableGeneratorApparatusType) {
        this.selectedMaintenanceTypes = _.clone(Constants.PortableMaintenanceTypes)
      }
    },
    getTitle() {
      if (!this.editingRecord) {
        return this.$t('xAddMaintenanceRecord')
      }
      return this.$t('xEditMaintenanceRecord')
    }
  },
  watch: {
    editedMaintenanceRecord: function(newVal, oldVal) {
      if (newVal) {
        this.dateCompleted = newVal.dateCompleted.split('T')[0]
        this.totalCost = newVal.totalCost
        this.runHours = newVal.runHours
        this.comments = newVal.comments
        this.recordToEditId = newVal.recordToEditId
        this.maintenanceTagsToAdd = newVal.maintenanceTagsToAdd
        this.maintenanceTagsToAdd.forEach(element => {
          if (!this.selectedMaintenanceTypes.some(tag => tag.name === element)) {
            this.selectedMaintenanceTypes.push({ name: element, selected: true })
          }
        })
        this.selectedMaintenanceTypes.forEach(element => {
          if (this.maintenanceTagsToAdd.some(tag => tag === element.name)) {
            element.selected = true
          }
        })
      }
    }
  },
  mounted() {
    this.setMaintenanceTypes()
    if (this.editedMaintenanceRecord) {
      this.dateCompleted = this.editedMaintenanceRecord.dateCompleted.split('T')[0]
      this.totalCost = this.editedMaintenanceRecord.totalCost
      this.runHours = this.editedMaintenanceRecord.runHours
      this.comments = this.editedMaintenanceRecord.comments
      this.recordToEditId = this.editedMaintenanceRecord.recordToEditId
      this.maintenanceTagsToAdd = this.editedMaintenanceRecord.maintenanceTagsToAdd
      this.maintenanceTagsToAdd.forEach(element => {
        if (!this.selectedMaintenanceTypes.some(tag => tag.name === element)) {
          this.selectedMaintenanceTypes.push({ name: element, selected: true })
        }
      })
      this.selectedMaintenanceTypes.forEach(element => {
        if (this.maintenanceTagsToAdd.some(tag => tag === element.name)) {
          element.selected = true
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.add-button:hover {
  background-color: white;
  color: var(--carbon-500);
}
.add-button:active {
  color: white;
  background-color: var(--carbon-500);
}
</style>
