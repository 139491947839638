var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "ml-3 mr-3 overlay",
          attrs: {
            show: _vm.loadingNotifications,
            rounded: "lg",
            id: "overlay"
          }
        },
        [
          _c("div", { staticClass: "row no-gutters mb-2 mt-4" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "h1",
                {
                  staticClass: "mb-0 pb-0",
                  attrs: { "data-test-hook": "pageTitle" }
                },
                [
                  _c("i", {
                    staticClass: "far fa-bell pr-1",
                    attrs: { "data-test-hook": "titleIcon" }
                  }),
                  _c(
                    "span",
                    {
                      attrs: {
                        "data-test-hook":
                          "notifications-card-title-account-settings"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xNotifications")))]
                  )
                ]
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "card", attrs: { "header-bg-variant": "white" } },
            [
              _c(
                "div",
                { staticClass: "card-body" },
                _vm._l(_vm.listOfApparatusLists, function(apparatusList, idx) {
                  return _c(
                    "div",
                    { key: idx },
                    [
                      apparatusList.length > 0
                        ? _c("div", [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col my-2" }, [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-test-hook":
                                        "hsb-heading-account-settings"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(_vm.getTypeText(apparatusList))
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm._l(apparatusList, function(apparatus, prefIndex) {
                        return _c(
                          "div",
                          {
                            key: apparatus.apparatusId,
                            staticClass: "accordion",
                            staticStyle: { width: "100%" },
                            attrs: { role: "tablist" }
                          },
                          [
                            _c("div", { staticClass: "apparatus-wrapper" }, [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row noClickOutline details-header",
                                    staticStyle: { "overflow-anchor": "none" },
                                    attrs: {
                                      role: "button",
                                      tabindex: "0",
                                      "aria-expanded": "false",
                                      "aria-controls":
                                        "accordion-" + apparatus.apparatusId,
                                      "data-toggle":
                                        "collapse-" + apparatus.apparatusId,
                                      "data-target":
                                        "#accordion-" + apparatus.apparatusId,
                                      "data-test-hook":
                                        "apparatus-button-" +
                                        apparatus.apparatusId +
                                        "-account-settings"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.getPreferenceInfo(apparatus)
                                      }
                                    }
                                  },
                                  [
                                    !_vm.apparatusPreference ||
                                    _vm.apparatusPreference.id !=
                                      apparatus.apparatusId
                                      ? _c("div", { staticClass: "col" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row align-items-center no-gutters"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-auto",
                                                  attrs: {
                                                    "data-test-hook":
                                                      "notifications-apparatus-name-account-settings"
                                                  }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        apparatus.apparatusName
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass: "col ml-2"
                                              }),
                                              _vm._m(0, true)
                                            ]
                                          )
                                        ])
                                      : _vm.apparatusPreference &&
                                        _vm.apparatusPreference.id ==
                                          apparatus.apparatusId
                                      ? _c("div", { staticClass: "col" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row align-items-center no-gutters"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-auto",
                                                  attrs: {
                                                    "data-test-hook":
                                                      "notifications-apparatus-name-account-settings"
                                                  }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        apparatus.apparatusName
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass: "col ml-2"
                                              }),
                                              _vm._m(1, true)
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm.loadingApparatusId == apparatus.apparatusId
                                  ? _c(
                                      "div",
                                      {
                                        class:
                                          "collapse-" + apparatus.apparatusId,
                                        attrs: {
                                          id:
                                            "accordion-" +
                                            apparatus.apparatusId,
                                          role: "tabpanel",
                                          visible:
                                            _vm.loadingApparatusId ==
                                            apparatus.apparatusId
                                        }
                                      },
                                      [
                                        _vm.apparatusPreference &&
                                        _vm.apparatusPreference.id ==
                                          apparatus.apparatusId
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pt-2 mt-3 border-top"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row pb-1 justify-content-between"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col align-self-center"
                                                      },
                                                      [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass: "mb-0",
                                                            attrs: {
                                                              "data-test-hook":
                                                                "notifications-primary-contact-title-account-settings"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-user-circle colorOrange mr-2"
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "xPrimaryContact"
                                                                )
                                                              ) + " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col text-right"
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-icon-button",
                                                            attrs: {
                                                              "data-test-hook":
                                                                "notifications-edit-primary-contact-button-account-settings"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showEditPrimaryModal(
                                                                  _vm.apparatusPreference,
                                                                  apparatus.apparatusId
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-pencil colorOrange hoverPointer fa-md"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col" },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            attrs: {
                                                              "data-test-hook":
                                                                "notifications-primary-contact-phone-account-settings"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "phone"
                                                                  )(
                                                                    _vm
                                                                      .apparatusPreference
                                                                      .phones[0]
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            attrs: {
                                                              "data-test-hook":
                                                                "notifications-primary-contact-email-account-settings"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .apparatusPreference
                                                                    .emails[0]
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row pt-3 align-items-center no-gutters"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col align-self-center"
                                                      },
                                                      [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass: "mb-0",
                                                            attrs: {
                                                              "data-test-hook":
                                                                "notifications-email-prefs-title-account-settings"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-envelope mr-2 colorOrange"
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "xEmailPreferences"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-auto text-right"
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-icon-button",
                                                            attrs: {
                                                              "data-test-hook":
                                                                "notifications-edit-email-prefs-button-account-settings"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showEditEmailPreferencesModal(
                                                                  _vm.apparatusPreference,
                                                                  apparatus.apparatusId,
                                                                  apparatus.apparatusType,
                                                                  _vm
                                                                    .apparatusPreference
                                                                    .threshold1,
                                                                  _vm
                                                                    .apparatusPreference
                                                                    .deviceType
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-cog colorOrange"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row pt-3 no-gutters align-items-center"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col" },
                                                      [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass: "mb-0",
                                                            attrs: {
                                                              "data-test-hook":
                                                                "notifications-sms-prefs-title-account-settings"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-mobile-android-alt mr-2 colorOrange"
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "xTextSmsPreferences"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-auto float-right"
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-icon-button",
                                                            attrs: {
                                                              "data-test-hook":
                                                                "notifications-edit-sms-prefs-button-account-settings"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showEditTextSmsPreferencesModal(
                                                                  _vm.apparatusPreference,
                                                                  apparatus.apparatusId,
                                                                  apparatus.apparatusType,
                                                                  _vm
                                                                    .apparatusPreference
                                                                    .threshold1
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-cog colorOrange"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row no-gutters pt-4 pb-2 align-items-center justify-content-between",
                                                    attrs: {
                                                      "data-test-hook":
                                                        "notifications-expand-additional-contacts-btn-account-settings"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-auto align-self-center"
                                                      },
                                                      [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass: "mb-0",
                                                            attrs: {
                                                              "data-test-hook":
                                                                "notifications-additional-contacts-title-account-settings"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-users mr-2 colorOrange"
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "xAdditionalContacts"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.apparatusPreference
                                                    .emails,
                                                  function(email, index) {
                                                    return _c(
                                                      "div",
                                                      { key: email },
                                                      [
                                                        index != 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row mb-2 align-items-center no-gutters"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "small",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "xEmail"
                                                                                    )
                                                                                  ) +
                                                                                    " " +
                                                                                    _vm._s(
                                                                                      index
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col",
                                                                            attrs: {
                                                                              "data-test-hook":
                                                                                "notifications-additional-contacts-email-account-settings"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  email
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-auto"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-icon-button",
                                                                        attrs: {
                                                                          "data-test-hook":
                                                                            "notifications-additional-contacts-email-delete-btn-account-settings"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.showDeleteModal(
                                                                              email,
                                                                              "email",
                                                                              index,
                                                                              _vm.apparatusPreference,
                                                                              prefIndex,
                                                                              apparatus.apparatusId
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "far fa-trash-can"
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-auto"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-icon-button",
                                                                        attrs: {
                                                                          "data-test-hook":
                                                                            "notifications-additional-contacts-email-edit-btn-account-settings"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.showAddEditModal(
                                                                              email,
                                                                              "email",
                                                                              index,
                                                                              "edit",
                                                                              _vm.apparatusPreference,
                                                                              prefIndex,
                                                                              apparatus.apparatusId
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "far fa-pencil"
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._l(
                                                  _vm.apparatusPreference
                                                    .phones,
                                                  function(phone, index) {
                                                    return _c(
                                                      "div",
                                                      { key: phone },
                                                      [
                                                        phone !=
                                                        _vm.apparatusPreference
                                                          .phones[0]
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mb-2 row align-items-center no-gutters"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "small",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "xPhoneNumber"
                                                                                    )
                                                                                  ) +
                                                                                    " " +
                                                                                    _vm._s(
                                                                                      index
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col",
                                                                            attrs: {
                                                                              "data-test-hook":
                                                                                "notifications-additional-contacts-phone-account-settings"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "phone"
                                                                                  )(
                                                                                    phone
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-auto"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-icon-button",
                                                                        attrs: {
                                                                          "data-test-hook":
                                                                            "notifications-additional-contacts-phone-delete-btn-account-settings"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.showDeleteModal(
                                                                              phone,
                                                                              "phone",
                                                                              index,
                                                                              _vm.apparatusPreference,
                                                                              prefIndex,
                                                                              apparatus.apparatusId
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "far fa-trash-can"
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-auto"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-icon-button",
                                                                        attrs: {
                                                                          "data-test-hook":
                                                                            "notifications-additional-contacts-phone-edit-btn-account-settings"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.showAddEditModal(
                                                                              phone,
                                                                              "phone",
                                                                              index,
                                                                              "edit",
                                                                              _vm.apparatusPreference,
                                                                              prefIndex,
                                                                              apparatus.apparatusId
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "far fa-pencil"
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row py-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col" },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-outline-warning",
                                                            attrs: {
                                                              disabled:
                                                                _vm
                                                                  .apparatusPreference
                                                                  .emails
                                                                  .length >= 4,
                                                              "data-test-hook":
                                                                "notifications-additional-contacts-email-add-btn-account-settings"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showAddEditModal(
                                                                  "",
                                                                  "email",
                                                                  0,
                                                                  "add",
                                                                  _vm.apparatusPreference,
                                                                  prefIndex,
                                                                  apparatus.apparatusId
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-plus"
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "xAddEmail"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "mt-2 mt-md-0 ml-2 btn btn-outline-warning",
                                                            attrs: {
                                                              disabled:
                                                                _vm
                                                                  .apparatusPreference
                                                                  .phones
                                                                  .length >= 4,
                                                              "data-test-hook":
                                                                "notifications-additional-contacts-phone-add-btn-account-settings"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showAddEditModal(
                                                                  "",
                                                                  "phone",
                                                                  0,
                                                                  "add",
                                                                  _vm.apparatusPreference,
                                                                  prefIndex,
                                                                  apparatus.apparatusId
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-plus"
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "xAddPhoneNumber"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              2
                                            )
                                          : !_vm.apparatusPreference
                                          ? _c("div", [_vm._m(2, true)])
                                          : _vm.loadingApparatusId ==
                                            apparatus.apparatusId
                                          ? _c("div", [_vm._m(3, true)])
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ]
          )
        ]
      ),
      _vm.modalInfo
        ? _c("EditPrimaryInfoModal", {
            attrs: { modalInfo: _vm.modalInfo },
            on: { saveEditPrimary: _vm.saveEditPrimary }
          })
        : _vm._e(),
      _vm.editEmailPreferencesModalInfo
        ? _c("EditEmailPreferencesModal", {
            attrs: {
              editEmailPreferencesModalInfo: _vm.editEmailPreferencesModalInfo
            },
            on: { buildPreferenceInfo: _vm.buildPreferenceInfo }
          })
        : _vm._e(),
      _vm.editTextSmsPreferencesModalInfo
        ? _c("EditTextSmsPreferencesModal", {
            attrs: {
              editTextSmsPreferencesModalInfo:
                _vm.editTextSmsPreferencesModalInfo
            },
            on: { buildPreferenceInfo: _vm.buildPreferenceInfo }
          })
        : _vm._e(),
      _vm.confirmDeleteModalInfo
        ? _c("ConfirmDeleteModal", {
            attrs: { confirmDeleteModalInfo: _vm.confirmDeleteModalInfo },
            on: { confirmDelete: _vm.confirmDelete }
          })
        : _vm._e(),
      _vm.addEditContactModalInfo
        ? _c("AddEditContactInfoModal", {
            attrs: { addEditContactModalInfo: _vm.addEditContactModalInfo },
            on: { saveAddEdit: _vm.saveAddEdit }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c("i", { staticClass: "fas fa-chevron-down" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c("i", { staticClass: "fas fa-chevron-up" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row text-center float-center" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", {
          staticClass: "spinner-border text-warning",
          staticStyle: { width: "5rem", height: "5rem" },
          attrs: { label: "Spinning" }
        })
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row text-center float-center" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", {
          staticClass: "spinner-border text-warning",
          staticStyle: { width: "5rem", height: "5rem" },
          attrs: { label: "Spinning" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }