var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-2 ml-md-2" }, [
    _c("div", { staticClass: "row no-gutters mb-2" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "h1",
          {
            staticClass: "mb-0 pb-0",
            attrs: { "data-test-hook": "subPageTitle" }
          },
          [
            _c("i", {
              staticClass: "far fa-badge-dollar colorOrange mr-1",
              attrs: { "data-test-hook": "subPageTitleIcon" }
            }),
            _c("span", { attrs: { "data-test-hook": "subPageTitleText" } }, [
              _vm._v(_vm._s(_vm.$t("xSubscription")))
            ])
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("p", { attrs: { "data-test-hook": "subPageBodyText" } }, [
              _vm._v(_vm._s(_vm.$t("xSubscriptionBodyText")))
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-2",
                attrs: {
                  type: "button",
                  title: _vm.$t("xViewSubscriptions"),
                  "data-test-hook": "subPageButton"
                },
                on: {
                  click: function($event) {
                    return _vm.openSubscriptionSettings()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xViewSubscriptions")) + " ")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }