<template>
  <div class="ml-3 mr-3" rounded="lg">
    <div class="row no-gutters mb-2 mt-4">
      <div class="col">
        <h1 class="mb-0 pb-0" data-test-hook="pageTitle">
          <i class="far fa-file-alt  pr-1" data-test-hook="titleIcon" />
          <span data-test-hook="docs-card-title-account-settings"> {{ $t('xDocumentsStatements') }}</span>
        </h1>
      </div>
    </div>
    <div class="card" header-bg-variant="white">
      <div class="card-body">
        <div class="row mt-2" v-if="invoicesLoading">
          <div class="col text-center float-center">
            <div
              class="spinner-border text-warning"
              style="width: 5rem; height: 5rem;"
              label="Spinning"
              data-test-hook="docs-loading-spinner-account-settings"
            />
          </div>
        </div>
        <div class="row" v-else-if="!invoicesLoading && !invoices">
          <div class="col">
            <i data-test-hook="docs-no-statements-text-account-settings">{{ $t('xNoStatementsAvailable') }}</i>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12" v-for="(invoice, index) in invoices" :key="index">
            <a @click="downloadInvoice(invoice)" href="#" data-test-hook="docs-invoice-account-settings">
              <i class="far fa-file-pdf mr-1" />
              {{ $t('xInvoice') }}
              {{ $moment(invoice.date).format($t('xShortDate')) }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      invoicesLoading: true,
      invoices: null
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      user: state => state.account.user
    })
  },
  methods: {
    downloadInvoice(invoice) {
      let fileName = 'Statement' + this.$moment(invoice.date).format('YYYY-MM-DD') + '.pdf'
      this.$store
        .dispatch('downloadInvoice', { organizationId: this.accountProfile.organizationId, invoiceId: invoice.id })
        .then(response => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          let fileLink = document.createElement('a')
          fileLink.target = '_blank'
          fileLink.href = fileURL
          fileLink.setAttribute('download', fileName)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToDownloadFile'),
            variant: 'danger'
          })
        })
    }
  },
  mounted() {
    this.$store
      .dispatch('findAccountInvoices', { organizationId: this.accountProfile.organizationId })
      .then(response => {
        if (response.status == 204) this.invoices = null
        else this.invoices = response.data
        this.invoicesLoading = false
      })
      .catch(error => {
        this.invoicesLoading = false
      })
  }
}
</script>
<style scoped lang="scss">
a {
  margin-bottom: 0.75em;
  display: block;
  color: var(--carbon-900);

  i {
    color: var(--brand-600);
  }

  &:hover {
    color: var(--carbon-900);
  }
}
</style>
