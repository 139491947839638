var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.accountProfile
      ? _c("div", { staticClass: "d-flex justify-content-center mb-3" }, [
          _c("div", {
            staticClass: "spinner-border text-warning ml-2",
            staticStyle: { width: "10rem", height: "10rem" }
          })
        ])
      : _c("div", [
          _vm.cdmaWarning
            ? _c(
                "div",
                {
                  staticClass: "pt-3",
                  on: {
                    click: function($event) {
                      return _vm.trackAppInsightsEvent("cdma-banner-click")
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticStyle: { "text-decoration": "none !important" },
                      attrs: { href: _vm.cdmaWarning.link, target: "_blank" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "alert alert-warning hoverPointer mb-0",
                          attrs: { role: "alert", show: "" }
                        },
                        [
                          _c("h2", [
                            _c("i", {
                              staticClass:
                                "fas fa-exclamation-triangle banner-category-warning"
                            }),
                            _vm._v(" " + _vm._s(_vm.cdmaWarning.title) + " ")
                          ]),
                          _c("p", [
                            _vm._v(" " + _vm._s(_vm.cdmaWarning.message) + " ")
                          ]),
                          _c("p", { staticClass: "mb-0 pb-0" }, [
                            _vm._v(
                              " " + _vm._s(_vm.cdmaWarning.linkText) + " "
                            ),
                            _c("i", { staticClass: "fas fa-arrow-right" })
                          ])
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm.currentAppConfig &&
          _vm.currentAppConfig.featureFlags &&
          _vm.currentAppConfig.featureFlags[_vm.billingMigrationFeatureFlag]
            ? _c(
                "div",
                {
                  staticClass: "alert alert-warning",
                  attrs: { role: "alert", show: "" }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("xBillingMaintenanceMessage")) + " "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  return _vm.$bvModal.show("banner-message-modal")
                }
              }
            },
            [
              _vm.showBannerMessage(_vm.bannerMessage)
                ? _c(
                    "div",
                    {
                      staticClass: "hoverPointer alert",
                      class: _vm.getBannerVariant(_vm.bannerMessage),
                      attrs: { role: "alert", show: "" }
                    },
                    [
                      _c("i", {
                        class: _vm.getBannerIconClassAndColor(_vm.bannerMessage)
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.bannerMessage.BannerMessage) + " "
                      ),
                      _c("strong", [
                        _vm._v(" " + _vm._s(_vm.$t("xLearnMore")) + " "),
                        _c("i", { staticClass: "fas fa-arrow-right" })
                      ])
                    ]
                  )
                : _vm._e()
            ]
          ),
          _c("div", { staticClass: "row justify-content-between" }, [
            _c(
              "div",
              { staticClass: "d-none d-md-block px-0 col-md-4 col-lg-3 col-0" },
              [
                _c(
                  "b-list-group",
                  {
                    staticClass: "nav account-nav-wrapper flex-column",
                    attrs: { id: "settingsMenu" }
                  },
                  _vm._l(_vm.childRoutes, function(route) {
                    return _c(
                      "b-list-group-item",
                      {
                        key: route.name,
                        staticClass: "nav-item",
                        class: _vm.$route.name == route.name,
                        attrs: {
                          to: { name: route.name },
                          "data-test-hook": route.label
                            .toLowerCase()
                            .replace(/\s/g, "-")
                            .concat("-nav-btn-account-settings")
                        }
                      },
                      [
                        _c(
                          "p",
                          {
                            attrs: {
                              "data-test-hook":
                                route.label.replace(` `, ``) + `Btn`
                            }
                          },
                          [
                            _c("i", { class: route.icon }),
                            _vm._v(" " + _vm._s(route.label) + " ")
                          ]
                        )
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md", attrs: { id: "childRouteContainer" } },
              [
                _c("div", { staticClass: "row d-md-none pt-2 mr-1 ml-1" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col hoverPointer pr-0 d-flex align-items-center"
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0 py-2 font-weight-bold",
                          on: {
                            click: function($event) {
                              return _vm.$router.go(-1)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-arrow-left colorOrange mr-2"
                          }),
                          _vm._v(" Back ")
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-auto ml-auto my-auto" },
                    [
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "d-md-none",
                          attrs: { right: "", variant: "outline-warning" },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [_vm._v(" Settings ")]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-list-group",
                            { attrs: { flush: "" } },
                            _vm._l(_vm.childRoutes, function(route) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: route.name,
                                  class: _vm.$route.name == route.name,
                                  attrs: {
                                    to: { name: route.name },
                                    "data-test-hook": route.label
                                      .toLowerCase()
                                      .replace(/\s/g, "-")
                                      .concat("-nav-btn-account-settings")
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      attrs: {
                                        "data-test-hook":
                                          route.label.replace(` `, ``) + `Btn`
                                      }
                                    },
                                    [
                                      _c("i", { class: route.icon }),
                                      _vm._v(" " + _vm._s(route.label) + " ")
                                    ]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in" } },
                  [_c("router-view")],
                  1
                )
              ],
              1
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }