var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.apparatus
        ? _c(
            "div",
            {
              staticClass: "card hoverPointer apparatus-card",
              class: _vm.getApparatusCardClass(),
              attrs: {
                "header-bg-variant": _vm.getHeaderBGVariant(),
                "header-class": "dashboard-card-header",
                "footer-class": "apparatus-card-footer",
                "data-test-hook": "apparatus-clickable-card-apparatus-card"
              },
              on: {
                click: function($event) {
                  return _vm.toDetails()
                }
              }
            },
            [
              (!_vm.generatorEnrollmentComplete() ||
                _vm.isInGracePeriod ||
                _vm.generatorIsDunning()) &&
              !_vm.apparatus.isDealerManaged
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header dashboard-card-header bg-statusWarning"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row w-100 no-gutters",
                            attrs: { "data-test-hook": "DisconnectedHeader" }
                          },
                          [
                            _c("div", { staticClass: "float-left col" }, [
                              _vm.generatorIsDunning() || _vm.isInGracePeriod
                                ? _c(
                                    "strong",
                                    {
                                      staticClass: "py-0 my-0",
                                      attrs: { "data-test-hook": "HeaderText" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("xUpdateYourPaymentMethod")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _c(
                                    "strong",
                                    {
                                      staticClass: "py-0 my-0",
                                      attrs: { "data-test-hook": "HeaderText" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "xFinishSettingUpYourProduct"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                            ]),
                            _vm._m(0)
                          ]
                        )
                      ]
                    )
                  ])
                : _vm.generatorDealerSubscriptionExpired()
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header dashboard-card-header bg-statusWarning"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row w-100 no-gutters",
                            attrs: { "data-test-hook": "NoSubscriptionHeader" }
                          },
                          [
                            _c("div", { staticClass: "float-left col" }, [
                              _c(
                                "strong",
                                {
                                  staticClass: "py-0 my-0",
                                  attrs: { "data-test-hook": "HeaderText" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "xYourDealerManagedSubscriptionHasExpired"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]),
                            _vm._m(1)
                          ]
                        )
                      ]
                    )
                  ])
                : _vm.warningHeader
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header dashboard-card-header bg-statusWarning"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row w-100 no-gutters",
                            attrs: { "data-test-hook": "WarningHeader" }
                          },
                          [
                            _c("div", { staticClass: "float-left col" }, [
                              _c(
                                "strong",
                                {
                                  staticClass: "py-0 my-0",
                                  attrs: { "data-test-hook": "HeaderText" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xWarningDetails")) +
                                      " "
                                  )
                                ]
                              )
                            ]),
                            _vm._m(2)
                          ]
                        )
                      ]
                    )
                  ])
                : _vm.faultHeader
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header dashboard-card-header bg-statusFault"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row w-100 no-gutters",
                            attrs: { "data-test-hook": "FaultHeader" }
                          },
                          [
                            _c("div", { staticClass: "float-left col" }, [
                              _c(
                                "strong",
                                {
                                  staticClass: "py-0 my-0 colorWhite",
                                  attrs: { "data-test-hook": "HeaderText" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("xFaultDetails")) + " "
                                  )
                                ]
                              )
                            ]),
                            _vm._m(3)
                          ]
                        )
                      ]
                    )
                  ])
                : _vm.disconnectedHeader
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header dashboard-card-header bg-statusDisconnected"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row w-100 no-gutters",
                            attrs: { "data-test-hook": "DisconnectedHeader" }
                          },
                          [
                            _c("div", { staticClass: "float-left col" }, [
                              _c(
                                "strong",
                                {
                                  staticClass: "py-0 my-0",
                                  attrs: { "data-test-hook": "HeaderText" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.noSignalMessage()) + " "
                                  )
                                ]
                              )
                            ]),
                            _vm._m(4)
                          ]
                        )
                      ]
                    )
                  ])
                : _vm.switchedOffHeader
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header dashboard-card-header bg-statusFault"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row w-100 no-gutters",
                            attrs: { "data-test-hook": "switchedOffHeader" }
                          },
                          [
                            _c("div", { staticClass: "float-left col" }, [
                              _c(
                                "strong",
                                {
                                  staticClass: "py-0 my-0 colorWhite",
                                  attrs: { "data-test-hook": "HeaderText" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xAttentionRequired")) +
                                      " "
                                  )
                                ]
                              )
                            ]),
                            _vm._m(5)
                          ]
                        )
                      ]
                    )
                  ])
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "card-header dashboard-card-header" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "center row w-100 no-gutters",
                            attrs: { "data-test-hook": "StandardHeader" }
                          },
                          [
                            _vm.apparatus.weather
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "float-left col-auto",
                                    attrs: {
                                      "data-test-hook": "ApparatusWeather"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      class: _vm._f("weatherIcon")(
                                        _vm.apparatus.weather.iconCode
                                      )
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.temperature) +
                                        "°" +
                                        _vm._s(_vm.temperatureUnits) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.device &&
                            _vm.device[0] &&
                            _vm.device[0].value &&
                            _vm.device[0].value.signalStrength
                              ? _c(
                                  "div",
                                  { staticClass: "float-left col-auto ml-2" },
                                  [
                                    _vm.getDeviceType() == _vm.wifi
                                      ? _c("i", {
                                          staticClass: "fa-solid fa-wifi",
                                          attrs: {
                                            "data-test-hook":
                                              "ApparatusSignalStrength"
                                          }
                                        })
                                      : _c("i", {
                                          staticClass: "fal fa-signal-alt",
                                          attrs: {
                                            "data-test-hook":
                                              "ApparatusSignalStrength"
                                          }
                                        }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.device[0].value.signalStrength
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._m(6)
                          ]
                        )
                      ]
                    )
                  ]),
              _c("div", {
                class: _vm.apparatus.isConnecting ? "connectingOverlay" : null
              }),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row d-flex" }, [
                  _c("div", { staticClass: "col-auto" }, [
                    _c(
                      "h4",
                      {
                        staticClass: "mb-0 py-2 pl-1 d-flex align-items-center"
                      },
                      [
                        _c("StatusIcon", {
                          staticClass: "mr-1 statusIcon",
                          attrs: {
                            apparatusStatus: _vm.apparatus.apparatusStatus,
                            isConnected: _vm.apparatus.isConnected,
                            showWarning: _vm.apparatus.showWarning,
                            isConnecting: _vm.apparatus.isConnecting,
                            deviceType: _vm.getDeviceType(),
                            "data-test-hook": "ApparatusStatusIcon"
                          }
                        }),
                        _c(
                          "span",
                          {
                            attrs: { "data-test-hook": "ApparatusStatusText" }
                          },
                          [_vm._v(_vm._s(_vm.getStatusText()))]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "row d-flex" }, [
                  _c("div", { staticClass: "col-4" }, [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: {
                        src: _vm.apparatus.heroImageUrl,
                        "data-test-hook": "ApparatusHeroImg",
                        alt: "Hero Image"
                      }
                    })
                  ]),
                  _c("div", { staticClass: "pt-4 col-8" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "ellipsis-overflow mb-2 col",
                          attrs: { "data-test-hook": "ApparatusAddress" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.apparatus.localizedAddress) + " "
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "h2",
                          {
                            staticClass: "pb-1 mb-0",
                            attrs: { "data-test-hook": "ApparatusName" }
                          },
                          [_vm._v(" " + _vm._s(_vm.apparatus.name) + " ")]
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card-footer apparatus-card-footer" }, [
                _vm.generatorEnrollmentComplete() &&
                _vm.subscription &&
                _vm.subscription[0] &&
                _vm.subscription[0].value &&
                _vm.subscription[0].value.type != _vm.NoSub
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm.apparatus.isDealerManaged ||
                        _vm.apparatus.isDealerUnmonitored
                          ? _c("span", { staticClass: "text-small" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatLabel")("xDealerManaged"))
                              )
                            ])
                          : _c("span", { staticClass: "text-small" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatLabel")("xPreferredDealer")
                                )
                              )
                            ])
                      ])
                    ])
                  : _vm._e(),
                _vm.generatorEnrollmentComplete() &&
                _vm.subscription &&
                _vm.subscription[0] &&
                _vm.subscription[0].value &&
                _vm.subscription[0].value.type != _vm.NoSub
                  ? _c("div", { staticClass: "row" }, [
                      _vm.apparatus && _vm.apparatus.preferredDealerName
                        ? _c("div", { staticClass: "col" }, [
                            _c(
                              "p",
                              {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  "data-test-hook": "ApparatusPreferredName"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fal fa-user-hard-hat"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.apparatus.preferredDealerName) +
                                    " "
                                )
                              ]
                            )
                          ])
                        : _c("div", { staticClass: "col" }, [
                            _c(
                              "p",
                              {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  "data-test-hook": "ApparatusPreferredName"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fal fa-user-hard-hat"
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("xNoneSelected")) + " "
                                )
                              ]
                            )
                          ])
                    ])
                  : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _vm.generatorIsDunning() || _vm.isInGracePeriod
        ? _c("UpdateCreditCardModal", {
            attrs: {
              existingPaymentInfo: _vm.paymentInfo,
              accountProfile: _vm.accountProfile,
              apparatusId: _vm.apparatusInfo.apparatusId,
              subscriptionStatus:
                this.subscription &&
                this.subscription[0] &&
                this.subscription[0].value &&
                this.subscription[0].value.status
                  ? this.subscription[0].value.status
                  : null
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-right mr-0 pr-0 col-auto" }, [
      _c("i", { staticClass: "fa-solid fa-arrow-right" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-right mr-0 pr-0 col-auto" }, [
      _c("i", { staticClass: "fa-solid fa-arrow-right" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-right mr-0 pr-0 col-auto" }, [
      _c("i", { staticClass: "fa-solid fa-arrow-right" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-right mr-0 pr-0 col-auto" }, [
      _c("i", { staticClass: "fa-solid fa-arrow-right" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-right mr-0 pr-0 col-auto" }, [
      _c("i", { staticClass: "fa-solid fa-arrow-right" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-right mr-0 pr-0 col-auto" }, [
      _c("i", {
        staticClass: "fa-solid fa-arrow-right",
        staticStyle: { color: "white" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-right text-right mr-0 pr-0 col" }, [
      _c("i", { staticClass: "fa-solid fa-arrow-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }