var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "d-flex justify-content-center mb-3 pt-4" },
          [
            _c("b-spinner", {
              staticStyle: { width: "5rem", height: "5rem" },
              attrs: { variant: "warning", label: "Spinning" }
            })
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "ml-3 mr-3",
                attrs: { rounded: "lg", id: "overlay" }
              },
              [
                _c("div", { staticClass: "row no-gutters mb-2 mt-4" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "h1",
                      {
                        staticClass: "mb-0 pb-0",
                        attrs: { "data-test-hook": "pageTitle" }
                      },
                      [
                        _c("i", {
                          staticClass: "far fa-user-circle pr-1",
                          attrs: { "data-test-hook": "titleIcon" }
                        }),
                        _c(
                          "span",
                          {
                            attrs: {
                              "data-test-hook":
                                "account-info-title-account-settings"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("xAccountInformation")))]
                        )
                      ]
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticClass: "card",
                    attrs: { "header-bg-variant": "white" }
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row mb-1" }, [
                        _c("div", { staticClass: "col-auto mb-1" }, [
                          _c("i", { staticClass: "far fa-user" }),
                          _c(
                            "strong",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                "data-test-hook":
                                  "account-holder-name-address-title-account-settings"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("xAccountHolder")) + " "
                              )
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c(
                              "popper",
                              {
                                attrs: {
                                  trigger: "hover",
                                  options: { placement: "right" }
                                }
                              },
                              [
                                _c("div", { staticClass: "popper" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "xPrimaryBillingContactAndAddressInfo"
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("i", {
                                  staticClass: "far fa-question-circle",
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row" }, [
                        !_vm.accountInfo
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center mb-3 pt-1"
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "spinner-border spinner-border-xl text-warning",
                                  staticStyle: {
                                    width: "5rem",
                                    height: "5rem"
                                  },
                                  attrs: {
                                    "data-test-hook": "LoadingSpinner",
                                    label: "Spinning"
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm.accountInfo
                          ? _c("div", { staticClass: "col" }, [
                              _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "account-holder-name-account-settings"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.accountInfo.ownerFirstName +
                                          " " +
                                          _vm.accountInfo.ownerLastName
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _vm.accountInfo.ownerAddress.line1
                                ? _c(
                                    "p",
                                    {
                                      attrs: {
                                        "data-test-hook":
                                          "account-holder-address-line-1-account-settings"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.accountInfo.ownerAddress.line1
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.accountInfo.ownerAddress.line2
                                ? _c(
                                    "p",
                                    {
                                      attrs: {
                                        "data-test-hook":
                                          "account-holder-address-line-2-account-settings"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.accountInfo.ownerAddress.line2
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "account-holder-address-city-state-zip-account-settings"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.accountInfo.ownerAddress.city +
                                          ", " +
                                          _vm.accountInfo.ownerAddress.region +
                                          " " +
                                          _vm.accountInfo.ownerAddress
                                            .postalCode
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "account-holder-phone-number-account-settings"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.accountInfo.ownerPhoneNumber) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "row pb-4" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-warning mt-3",
                              attrs: {
                                type: "button",
                                title: _vm.$t("xUpdateAccountInfo"),
                                "data-test-hook":
                                  "update-account-info-button-account-settings"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openEditAccountModal()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("xUpdateAccountInfo")) + " "
                              )
                            ]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "row pt-4" }, [
                        _c("div", { staticClass: "col-auto mb-1" }, [
                          _c("i", { staticClass: "far fa-lock colorOrange" }),
                          _c(
                            "strong",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                "data-test-hook":
                                  "account-holder-login-title-account-settings"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("xAccountLogin")) + " "
                              )
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c(
                              "popper",
                              {
                                attrs: {
                                  trigger: "hover",
                                  options: { placement: "right" }
                                }
                              },
                              [
                                _c("div", { staticClass: "popper" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "xEmailUsedToLoginAndRegisterMessage"
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("i", {
                                  staticClass: "far fa-question-circle",
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-auto" }, [
                          _vm.accountProfile.identityEmail
                            ? _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "account-holder-login-email-account-settings"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.accountProfile.identityEmail) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ]),
                      _c("div", { staticClass: "row mb-4" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-warning mt-3",
                              attrs: {
                                type: "button",
                                title: _vm.$t("xUpdateAccountEmail"),
                                "data-test-hook":
                                  "update-account-email-button-account-settings"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.updateEmail(
                                    _vm.accountProfile.identityEmail
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("xUpdateAccountEmail")) +
                                  " "
                              ),
                              _c("i", { staticClass: "fas fa-external-link" })
                            ]
                          )
                        ])
                      ]),
                      _vm._m(0),
                      _c("div", { staticClass: "row mb-4" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-outline-danger rounded-pill mt-4",
                              attrs: {
                                type: "button",
                                disabled: _vm.isImpersonating,
                                title: _vm.deleteAccountTitle(),
                                "data-test-hook":
                                  "delete-account-button-account-settings"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deleteAccount()
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-trash-alt colorOrange"
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("xDeleteAccount")) + " "
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ]
            ),
            _c(
              "Modal",
              {
                attrs: {
                  modalId: "unable-to-delete-account-modal",
                  size: "modal-lg"
                }
              },
              [
                _c(
                  "ModalHeader",
                  {
                    attrs: {
                      title: _vm.$t("xUnableToDeleteAccount"),
                      icon: "fa-regular fa-trash-can"
                    }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-icon-button btn-icon-button--gray",
                        attrs: {
                          "data-test-hook": "unable-to-delete-account-x-button"
                        },
                        on: {
                          click: function($event) {
                            return _vm.closeModal(
                              "unable-to-delete-account-modal"
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-times" })]
                    )
                  ]
                ),
                _c("ModalBody", [
                  _c("p", {
                    attrs: { "data-test-hook": "modalBodyText1" },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("xVppDeleteAccountModalBody1"))
                    }
                  }),
                  _c(
                    "p",
                    {
                      staticClass: "mt-3",
                      attrs: { "data-test-hook": "modalBodyText2" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("xVppDeleteAccountModalBody2")) +
                          " "
                      )
                    ]
                  )
                ]),
                _c("ModalFooter", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { "data-test-hook": "modalDismissButton" },
                      on: {
                        click: function($event) {
                          return _vm.closeModal(
                            "unable-to-delete-account-modal"
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xDismiss")) + " ")]
                  )
                ])
              ],
              1
            ),
            _c("EditAccountInfoModal", {
              attrs: { shouldSetModal: _vm.shouldSetModal },
              on: {
                shouldSetModal: function($event) {
                  return _vm.setShouldSetModal()
                }
              }
            }),
            _c("DeleteAccountModal", {
              attrs: { deleteAccountModalInfo: _vm.deleteAccountModalInfo },
              on: {
                showConfirmDeleteAccountModal: _vm.showConfirmDeleteAccountModal
              }
            }),
            _c("ConfirmDeleteAccountModal", {
              attrs: {
                confirmDeleteAccountModalInfo: _vm.confirmDeleteAccountModalInfo
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("hr", { staticClass: "mx-0 px-0" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }