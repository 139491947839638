import i18n from '@/lang/'

export const SeverityRanking = {
  Unknown: 0,
  CommsIssue: 50,
  Ready: 100,
  RunningOther: 200,
  RunningExercise: 300,
  RunningUtilityLoss: 400,
  Warning: 500,
  Fault: 600,
  FaultOff: 650
}

export const days = [
  { value: 0, text: i18n.t('xSunday') },
  { value: 1, text: i18n.t('xMonday') },
  { value: 2, text: i18n.t('xTuesday') },
  { value: 3, text: i18n.t('xWednesday') },
  { value: 4, text: i18n.t('xThursday') },
  { value: 5, text: i18n.t('xFriday') },
  { value: 6, text: i18n.t('xSaturday') }
]

export const intervals = [
  { value: 0, text: i18n.t('xWeekly') },
  { value: 1, text: i18n.t('xBiWeekly') },
  { value: 2, text: i18n.t('xMonthly') }
]

export const WeeklyInterval = 0
export const BiWeeklyInterval = 1
export const MonthlyInterval = 2

export const nexusExerciseInterval = [{ value: 0, text: i18n.t('xWeekly') }]

export const daysOfMonth = [
  { value: 1, text: '1st Day' },
  { value: 2, text: '2nd Day' },
  { value: 3, text: '3nd Day' },
  { value: 4, text: '4th Day' },
  { value: 5, text: '5th Day' },
  { value: 6, text: '6th Day' },
  { value: 7, text: '7th Day' },
  { value: 8, text: '8th Day' },
  { value: 9, text: '9th Day' },
  { value: 10, text: '10th Day' },
  { value: 11, text: '11th Day' },
  { value: 12, text: '12th Day' },
  { value: 13, text: '13th Day' },
  { value: 14, text: '14th Day' },
  { value: 15, text: '15th Day' },
  { value: 16, text: '16th Day' },
  { value: 17, text: '17th Day' },
  { value: 18, text: '18th Day' },
  { value: 19, text: '19th Day' },
  { value: 20, text: '20th Day' },
  { value: 21, text: '21st Day' },
  { value: 22, text: '22nd Day' },
  { value: 23, text: '23rd Day' },
  { value: 24, text: '24th Day' },
  { value: 25, text: '25th Day' },
  { value: 26, text: '26th Day' },
  { value: 27, text: '27th Day' },
  { value: 28, text: '28th Day' }
]

export const ManageMaintenancePanels = [
  '3',
  '03',
  '4',
  '04',
  '5',
  '05',
  '6',
  '06',
  '7',
  '07',
  '8',
  '08',
  '9',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '21',
  '22',
  '23',
  '25'
]

export const CanSendActionCommands = ['21', '22', '23', '25']

export const NexusPanels = ['3', '03', '4', '04', '5', '05', '6', '06', '7', '07', '8', '08']

export const CountriesGetPaidSubs = ['US', 'CA']

export const TanksAllowedCountries = ['US', 'CA', 'MX', 'PR', 'DO', 'BZ', 'CR', 'SV', 'GT', 'HN', 'NI', 'PA']

export const HsbMaintenanceTypes = [
  { name: i18n.t('xChangedOilAndFilter'), selected: false },
  { name: i18n.t('xChangedAirFilter'), selected: false },
  { name: i18n.t('xChangedSparkPlug'), selected: false },
  { name: i18n.t('xCheckedOilLevel'), selected: false },
  { name: i18n.t('xInspectedBattery'), selected: false },
  { name: i18n.t('xReplacedBattery'), selected: false },
  { name: i18n.t('xCheckedFuelLines'), selected: false },
  { name: i18n.t('xCheckedLouvers'), selected: false },
  { name: i18n.t('xCompletedScheduledMaintenance'), selected: false }
]
export const PortableMaintenanceTypes = [
  { name: i18n.t('xChangedAirFilter'), selected: false },
  { name: i18n.t('xChangedSparkPlug'), selected: false },
  { name: i18n.t('xChangedOil'), selected: false }
]

export const ApparatusStatus = {
  Commissioned: 'Commissioned',
  Manufactured: 'Manufactured',
  Retired: 'Retired'
}

export const PreprodSlotName = 'preprod'

export const HsbApparatusType = 0
export const IndustrialGenApparatusType = 1
export const FuelTankApparatusType = 2
export const PortableBatteryApparatusType = 3
export const PortableGeneratorApparatusType = 4
export const PortableInverterApparatusType = 5
export const VehicleChargerApparatusType = 6
export const ThermostatApparatusType = 7

export const ApparatusPropertyGeneral = 0
export const ApparatusPropertyConnectionType = 1
export const ApparatusPropertySubscription = 2
export const ApparatusPropertyDevice = 3

// enum IAP/organization subscription statuses
export const SubscriptionDetailPurchaseIntent = 0
export const SubscriptionDetailActive = 1
export const SubscriptionDetailPendingCancel = 2
export const SubscriptionDetailCanceled = 3
export const SubscriptionDetailPendingProductChange = 4
export const SubscriptionDetailRefund = 5

// enum IAP/organization subscription reasons
export const SubscriptionDetailsReasonPurchase = 0
export const SubscriptionDetailsReasonRenewal = 1
export const SubscriptionDetailsReasonChangedApparatus = 2
export const SubscriptionDetailsReasonPendingChangeCanceled = 3
export const SubscriptionDetailsReasonUserDisabledAutoRenew = 4
export const SubscriptionDetailsReasonUserCanceled = 5
export const SubscriptionDetailsReasonGracePeriodStarted = 6
export const SubscriptionDetailsReasonGracePeriodExpired = 7
export const SubscriptionDetailsReasonRefundIssued = 8
export const SubscriptionDetailsReasonRefundReversed = 9
export const SubscriptionDetailsReasonUpgrade = 10
export const SubscriptionDetailsReasonDowngrade = 11
export const SubscriptionDetailsReasonPendingPurchaseCanceled = 12
export const SubscriptionDetailsReasonPendingCancelReverted = 13

//enum subscription statuses (legacy)
export const SubscriptionInactive = 0
export const SubscriptionActive = 1
export const SubscriptionPendingCancellation = 2
export const SubscriptionPendingTermination = 3
export const SubscriptionSuspended = 4
export const SubscriptionCancelled = 5
export const SubscriptionTerminated = 6
export const SubscriptionPendingSuspension = 7
export const SubscriptionDunning = 8
//string subscription statuses (legacy)
export const PendingCancellationStatus = 'PendingCancellation'
export const ActiveStatus = 'Active'
export const InactiveStatus = 'Inactive'
export const PendingTermination = 'PendingTermination'
export const SuspendedStatus = 'Suspended'
export const CancelledStatus = 'Cancelled'
export const TerminatedStatus = 'Terminated'
export const PendingSuspensionStatus = 'PendingSuspension'

export const DealerManagedProductName = 'Dealer Managed'

export const NoSubscription = 0
export const FreeSubscription = 1
export const PaidSubscription = 2
export const ManagedSubscription = 3

export const CdmaUpgradeRemindMeDate = 'cdmaUpgradeRemindMeDate'
export const CdmaUpdateRemindMeDays = 'CdmaUpdateRemindMeDays'
export const CdmaUpgradeShowPopUp = 'CdmaUpgradeShowPopUp'
export const CdmaUpgradeLearnMoreDate = 'cdmaUpgradeLearnMoreDate'

export const DisplayDistanceUom = 'displayDistanceUom'
export const DisplayTemperatureUom = 'displayTemperatureUom'

export const FreeWifiEthProductId = 'p-free-wifi-annual'
export const WiFiMonthlyProductId = 'p-premium-wifi-monthly'
export const WiFiAnnualProductId = 'p-premium-wifi-annual'
export const EthMonthlyProductId = 'p-premium-eth-monthly'
export const EthAnnualProductId = 'p-premium-eth-annual'
export const LteMonthlyProductId = 'p-premium-lte-monthly'
export const LteAnnualProductId = 'p-premium-lte-annual'
export const CdmaMonthlyProductId = 'p-premium-cdma-monthly'
export const CdmaAnnualProductId = 'p-premium-cdma-annual'
export const DealerManagedProductId = 'p-free-dealermanaged-annual'
export const TankUtilityFreeProductId = 'p-free-tankutility-monthly'
export const TankUtilityPremiumProductId = 'p-premium-tankutility-annual'
export const TankUtilityDealerManagedProductId = 'p-free-dealermanaged-tankutility-annual'
export const PortablesProductId = 'p-free-portablegenerator-monthly'
export const HomePremiumOneYearlyProductId = 'p-home-1-yearly'

export const TransferTypeGenerator = 'FreeGenerator'
export const TransferTypeAccount = 'Account'

export const StatusIsUnknown = 0
export const StatusIsReady = 1
export const StatusIsRunning = 2
export const StatusIsExercising = 3
export const StatusIsWarning = 4
export const StatusIsFault = 5
export const StatusIsCommsIssue = 6
export const StatusIsDisconnected = 7
export const StatusIsSwitchedOff = 8

export const VirtualPowerPlantFF = 'Any_VirtualPowerPlant'
export const BillingMigrationFeatureFlag = 'Any_Billing_Maintenance'
export const MessageCenterFF = 'Any_MessageCenter'
export const WebBannerMessageConfig = 'Web_Banner_Message'
export const DocumentsStatementsFF = 'Any_DocumentsStatementsPage'
export const TankMonitorInstallVideoUrlConfig = 'TankUtility_Setup_Video_Url'
export const AddPortablesGenBtOnDashboardFF = 'Any_PortableGeneratorMaintenance'
export const G3DeviceFeatureFlag = 'Web_G3_Feature_Flag'

export const TankDeviceIdValidationCode_Available = 0
export const TankDeviceIdValidationCode_AlreadyEnrolled = 1
export const TankDeviceIdValidationCode_NotFound = 2
export const TankDeviceIdValidationCode_WiFiNotDeployed = 3
export const TankDeviceIdValidationCode_DisabledDealerManaged = 4
export const TankDeviceIdValidationCode_DisabledNonDealerManaged = 5

export const AccountMessagesMessageCategory = 0
export const AppUpdatesMessageCategory = 1

export const GridServicesEventType = 0

export const GridServicesPendingActiveStatus = 0
export const GridServicesActiveStatus = 1
export const GridServicesPendingInactiveStatus = 2
export const GridServicesPendingCancelStatus = 3
export const GridServicesCompleteStatus = 4

export const GridServicesCompletionTypeOk = 0
export const GridServicesCompletionTypeUnexpected = 1
export const GridServicesCompletionTypeUnreported = 2
export const GridServicesCompletionTypeError = 3
export const GridServicesCompletionTypeInactive = 4
export const GridServicesCompletionTypeCanceled = 5

export const TuSnmpTypeFuelType = 0
export const TuSnmpTypeCapacity = 1
export const TuSnmpTypeOrientation = 2
export const TuSnmpTypeConsumptionTypes = 3
export const TuSnmpTypeFixedTransmissionTime = 4
export const TuSnmpTypeReadingLevel = 5
export const TuSnmpTypeTransmissionInterval = 6
export const TuSnmpTypeThreshold1 = 7
export const TuSnmpTypeThreshhold2 = 8
export const TuSnmpTypeFuelLevel = 9
export const TuSnmpTypeTemperature = 10
export const TuSnmpTypeLastReading = 11
export const TuSnmpTypeDeviceVersion = 12
export const TuSnmpTypeFuelDealerId = 13
export const TuSnmpTypeStatus = 14
export const TuSnmpTypeAverageConsumption = 15
export const TuSnmpTypeChangeOfValue = 16
export const TuSnmpTypeBatteryLevel = 17

//these should match PreferenceType enum in PreferenceType.cs
export const PreferenceTypeUnknown = 0
export const PreferenceTypeStatusChange = 1
export const PreferenceTypeFault = 2
export const PreferenceTypeWarning = 3
export const PreferenceTypeMaintenance = 4
export const PreferenceTypeExercise = 5
export const PreferenceTypeWeeklySummary = 6
export const PreferenceTypeMonthSummary = 7
export const PreferenceTypeMessage = 8
export const PreferenceTypeGridServices = 9
export const PreferenceTypeTankBatteryWarning = 10
export const PreferenceTypeTankBatteryCritcal = 11
export const PreferenceTypeTankFuelWarning = 12
export const PreferenceTypeDisconnected = 13

export const UpgradeEventType = 0
export const DowngradeEventType = 1
export const TransferEventType = 2
export const CancelEventType = 3
export const RemoveEventType = 4
export const GetStartedVisit = 5

export const HowToUpgradeHelpUrl =
  'https://support.generac.com/s/article/How-Do-I-Upgrade-My-CDMA-Device-to-the-LTE-Device'

export const CDMADoNotUpgradePanels = ['18', '19', '20']

export const TankUtilityWifiDeviceType = 'wifi-tankutility-v1'
export const TankUtilityLteDeviceTypeV1 = 'lte-tankutility-v1'
export const TankUtilityLteDeviceTypeV2 = 'lte-tankutility-v2'
export const HsbWifiDeviceType = 'wifi'
export const HsbLteDeviceType = 'lte'
export const HsbEthDeviceType = 'eth'
export const HsbCdmaDeviceType = 'cdma'
export const HsbG3DeviceType = 'dual-generator-v1'

export const HeatingOilSnmpValue = 'heating_oil'
export const TuPropertiesOilValue = 'oil' // Comes through in TuProperties as "Oil", not "heating_oil." Not sure why that discrepancy exists

export const TankSnmpNames = {
  threshold1: 'Threshold1'
}

export const ANNUAL = 'annual'
export const MONTHLY = 'monthly'
export const FREE = 'free'

export const DealerCategoryResidetialAndCommercialDealers = 'ResidetialAndCommercialDealers'
export const DealerCategoryPortablesDealers = 'PortablesDealers'

export const UserEmailLocalStorageKey = 'userEmail'
export const ForceSignOutErrorLocalStorageKey = 'signOutErrorMessage'

// Store options are: 0 = Apple, 1 = Google, 2 = Web, 3 = Dealer
export const SubscriptionStoreTypes = {
  APPLE: 'Apple',
  GOOGLE: 'Google',
  WEB: 'Web',
  DEALER: 'Dealer'
}

export const HsbApparatusStringType = 'Generator'
export const IndustrialGenApparatusStringType = 'IndustrialGenerator'
export const FuelTankApparatusStringType = 'FuelTank'
export const PortableBatteryApparatusStringType = 'PortableBattery'
export const PortableGeneratorApparatusStringType = 'PortableGenerator'
export const PortableInverterApparatusStringType = 'PortableInverter'
export const VehicleChargerApparatusStringType = 'VehicleCharger'
export const ThermostatApparatusStringType = 'Thermostat'
