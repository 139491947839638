<template>
  <div class="mt-2 ml-md-2">
    <div class="row no-gutters mb-2">
      <div class="col">
        <h1 class="mb-0 pb-0" data-test-hook="subPageTitle">
          <i class="far fa-badge-dollar colorOrange mr-1" data-test-hook="subPageTitleIcon" />
          <span data-test-hook="subPageTitleText">{{ $t('xSubscription') }}</span>
        </h1>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p data-test-hook="subPageBodyText">{{ $t('xSubscriptionBodyText') }}</p>
            <button
              type="button"
              class="btn btn-primary mt-2"
              @click="openSubscriptionSettings()"
              :title="$t('xViewSubscriptions')"
              data-test-hook="subPageButton"
            >
              {{ $t('xViewSubscriptions') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openSubscriptionSettings() {
      this.$router.push({ name: 'settingsSubscriptions' })
    }
  }
}
</script>
