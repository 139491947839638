import i18n from '@/lang/'
import Vue from 'vue'
import moment from 'moment'
import * as Constants from '@/constants'

export function deepTrim(obj) {
  for (var prop in obj) {
    var value = obj[prop],
      type = typeof value
    if (value != null && (type == 'string' || type == 'object') && obj.hasOwnProperty(prop)) {
      if (type == 'object') {
        deepTrim(obj[prop])
      } else {
        obj[prop] = obj[prop].trim()
      }
    }
  }
}

export function displayLongDateTime(date) {
  if (date) {
    return moment
      .utc(date)
      .tz(moment.tz.guess())
      .format(i18n.t('xLongDateTime'))
  } else return null
}

export function displayShortDate(date) {
  if (date) {
    return moment
      .utc(date)
      .tz(moment.tz.guess())
      .format(i18n.t('xShortDate'))
  } else {
    return 'N/A'
  }
}

export function displayExtraShortTimeWithTZ(date) {
  if (date) {
    return moment
      .utc(date)
      .tz(moment.tz.guess())
      .format(i18n.t('xExtraShortTime'))
  } else return 'N/A'
}

export function displayShortDateTimeWithTZ(date) {
  if (date) {
    return moment
      .utc(date)
      .tz(moment.tz.guess())
      .format(i18n.t('xShortDateTimeWithTZ'))
  } else {
    return 'N/A'
  }
}

export function displayShortTime(date) {
  if (date) {
    return moment
      .utc(date)
      .tz(moment.tz.guess())
      .format(i18n.t('xShortTime'))
  } else return 'N/A'
}

export function displayShortDateTime(date) {
  if (date) {
    return moment(date)
      .tz(moment.tz.guess())
      .format(i18n.t('xShortDateTime'))
  } else return 'N/A'
}

export function creditCardValidation(evt, cc) {
  evt = evt ? evt : window.event
  var charCode = evt.which ? evt.which : evt.keyCode
  if (charCode < 48 || charCode > 57 || (cc && cc.length > 15)) {
    evt.preventDefault()
  } else {
    return true
  }
}

export function cvvValidation(evt, cvv) {
  evt = evt ? evt : window.event
  var charCode = evt.which ? evt.which : evt.keyCode
  if (charCode < 48 || charCode > 57 || (cvv && cvv.length > 3)) {
    evt.preventDefault()
  } else {
    return true
  }
}

export function consentValidation(consent) {
  return consent
}

export function addPendingApparatus(apparatus) {
  var pendingApparatuses = JSON.parse(localStorage.getItem('pendingApparatuses'))
  if (pendingApparatuses == null) {
    pendingApparatuses = []
  }
  pendingApparatuses.push(apparatus)
  localStorage.setItem('pendingApparatuses', JSON.stringify(pendingApparatuses))
}

export function checkPendingApparatuses(apparatusIds, commit) {
  var pendingApparatuses = JSON.parse(localStorage.getItem('pendingApparatuses'))
  if (pendingApparatuses != null) {
    pendingApparatuses.forEach((pendingApparatus, index) => {
      if (apparatusIds.includes(pendingApparatus.id)) {
        pendingApparatuses.splice(index, 1)
      } else {
        commit('pushPendingApparatus', pendingApparatus.apparatusType)
      }
    })
    localStorage.setItem('pendingApparatuses', JSON.stringify(pendingApparatuses))
  }
}

export function subscriptionActive(subscription) {
  if (
    subscription &&
    subscription.status &&
    (subscription.status == Constants.SubscriptionActive ||
      subscription.status == Constants.SubscriptionPendingCancellation ||
      subscription.status == Constants.SubscriptionPendingTermination ||
      subscription.status == Constants.SubscriptionPendingSuspension)
  ) {
    return true
  }
  return false
}

export function subscriptionPaid(subscription) {
  if (
    subscription &&
    subscription.type &&
    (subscription.type == Constants.PaidSubscription || subscription.type == Constants.ManagedSubscription)
  ) {
    return true
  }
  return false
}

export function DisplayAddress(data) {
  if (!data) return 'N/A'
  if (data.line2) {
    return data.line1 + ' ' + data.line2 + ' ' + data.city + ', ' + data.region + ' ' + data.postalCode
  } else return data.line1 + ' ' + data.city + ', ' + data.region + ' ' + data.postalCode
}

export function updateEmail(email) {
  window.location.replace(vueAppApiBaseAddress() + `Auth/UpdateProfile?email=${encodeURIComponent(email)}`)
}

export function vueAppApiBaseAddress() {
  return document.cookie.indexOf(Constants.PreprodSlotName) != -1 ||
    window.location.href.indexOf(Constants.PreprodSlotName) != -1
    ? process.env.VUE_APP_API_BASE_ADDRESS_PREPROD
    : process.env.VUE_APP_API_BASE_ADDRESS
}

export function vueAppBaseAddress() {
  return document.cookie.indexOf(Constants.PreprodSlotName) != -1 ||
    window.location.href.indexOf(Constants.PreprodSlotName) != -1
    ? process.env.VUE_APP_BASE_ADDRESS_PREPROD
    : process.env.VUE_APP_BASE_ADDRESS
}

export function signOut() {
  this.$bvModal
    .msgBoxConfirm(this.$t('xAreYouSureLogout'), {
      size: 'sm',
      buttonSize: 'lg',
      okVariant: 'warning',
      okTitle: this.$t('xYes').toUpperCase(),
      cancelVariant: 'outline-warning',
      cancelTitle: this.$t('xNo').toUpperCase(),
      footerClass: 'p-2',
      hideHeader: true,
      centered: true
    })
    .then(value => {
      if (value) {
        this.$store.dispatch('clearAll')
        window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
      }
    })
}

export function signOutAccountCreation() {
  const h = this.$createElement
  const modalMessage = h('div', { class: ['foobar'] }, [
    h('p', [this.$t('xAreYouSureLogoutAccountCreationStartText'), <br />, <br />, this.$t('xAreYouSureLogout')])
  ])
  this.$bvModal
    .msgBoxConfirm([modalMessage], {
      size: 'md',
      buttonSize: 'lg',
      okVariant: 'warning',
      okTitle: this.$t('xLogout'),
      cancelVariant: 'outline-warning',
      cancelTitle: this.$t('xCancel'),
      footerClass: 'p-2',
      hideHeader: true,
      centered: true
    })
    .then(value => {
      if (value) {
        this.$store.dispatch('clearAll')
        window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
      }
    })
}

export function forceSignOut() {
  this.$store.dispatch('clearAll')
  window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
}

export function getSortOrder(prop) {
  return function(a, b) {
    if (a[prop] > b[prop]) {
      return 1
    } else if (a[prop] < b[prop]) {
      return -1
    }
    return 0
  }
}

export function notImplemented() {
  this.$store.dispatch('displayToast', { title: this.$t('xError'), message: null, variant: 'danger' })
}

export function hasDevice(value) {
  if (value && value.deviceInfo && value.deviceInfo.deviceId && value.deviceInfo.deviceType) {
    return true
  } else return false
}

export function hasAssociatedAccount(value) {
  return value && value.organizationId ? true : false
}

export function getSubscriptionClass(subscriptions) {
  if (subscriptions && subscriptions[0].status == 'Active') return 'active-subscription'
  else return 'inactive-subscription'
}

export function genWithoutWifiModule(modelNumber) {
  let lastChar = modelNumber.slice(-1)
  if (lastChar == '8' || lastChar == '9') {
    return true
  } else return false
}

export function canHavePaidSubscription(accountInfo) {
  if (
    accountInfo &&
    accountInfo.address &&
    (accountInfo.address.country === 'US' || accountInfo.address.country === 'CA')
  ) {
    return true
  } else return false
}

export function tankCanHavePaidSubscription(accountInfo) {
  if (accountInfo && accountInfo.address && Constants.TanksAllowedCountries.includes(accountInfo.address.country)) {
    return true
  } else return false
}

export function trackAppInsightsEvent(name, properties) {
  this.$appInsights.trackEvent({
    name: name,
    properties: properties
  })
}

export function subscriptionPaidAndNotManaged(subscription) {
  if (subscription && subscription.type && subscription.type == Constants.PaidSubscription) {
    return true
  }
  return false
}

export function subscriptionManaged(subscription) {
  if (subscription && subscription.type && subscription.type == Constants.ManagedSubscription) {
    return true
  }
  return false
}

export function messageCenterEnabled(currentAppConfig) {
  return currentAppConfig && currentAppConfig.featureFlags && currentAppConfig.featureFlags[Constants.MessageCenterFF]
}

export function virtualPowerPlantEnabled(currentAppConfig) {
  return (
    currentAppConfig && currentAppConfig.featureFlags && currentAppConfig.featureFlags[Constants.VirtualPowerPlantFF]
  )
}
export function g3DeviceFeatureFlagEnabled(currentAppConfig) {
  return currentAppConfig && currentAppConfig.featureFlags[Constants.G3DeviceFeatureFlag]
}

export function showBannerMessage(bannerMessage) {
  if (bannerMessage) {
    let now = moment().toISOString()
    if (now > bannerMessage.StartDate && now < bannerMessage.EndDate) return true
  } else return false
}

export function getBannerVariant(bannerMessage) {
  if (bannerMessage) {
    if (bannerMessage.Category == 0) return 'alert-warning'
  }
  return ''
}

export function getBannerIconClassAndColor(bannerMessage) {
  if (bannerMessage) {
    if (bannerMessage.Category == 0) return 'fas fa-exclamation-triangle banner-category-warning'
  }
  return ''
}

export function documentsStatementsPageEnabled(currentAppConfig) {
  return (
    currentAppConfig && currentAppConfig.featureFlags && currentAppConfig.featureFlags[Constants.DocumentsStatementsFF]
  )
}

export function createEventLogObjectFromDashboardPage(apparatus, profile, isImpersonating, eventType) {
  let eventToLog = {
    event: eventType,
    ownerOrganizationId: profile?.organizationId,
    apparatusId: apparatus?.apparatusId,
    userId: profile?.userId,
    properties: {
      apparatusType: apparatus?.type == Constants.FuelTankApparatusType ? 'FuelTank' : 'Generator',
      deviceType:
        apparatus?.type == Constants.FuelTankApparatusType
          ? 'tankutility'
          : properties.filter(x => x.type == Constants.ApparatusPropertyDevice)[0]?.value?.deviceType,
      deviceId:
        apparatus?.type == Constants.FuelTankApparatusType
          ? null
          : properties.filter(x => x.type == Constants.ApparatusPropertyDevice)[0]?.value?.deviceId,
      adminInitiated: isImpersonating,
      isDealerManaged: apparatus?.isDealerManaged
    }
  }
  return eventToLog
}

export function createEventLogObjectFromSubscriptionsPage(
  apparatus,
  subscription,
  profile,
  isImpersonating,
  eventType,
  newSubscriptionId,
  optionalReason,
  cancelDetails = null,
  couponCode = null
) {
  let eventToLog = {
    event: eventType,
    ownerOrganizationId: profile?.organizationId,
    apparatusId: apparatus?.apparatusId,
    userId: profile?.userId,
    properties: {
      deviceType: apparatus?.deviceType,
      adminInitiated: isImpersonating,
      reason: optionalReason,
      details: cancelDetails,
      oldSubscriptionId: subscription && subscription[0] ? subscription[0]?.productId : null,
      newSubscriptionId: newSubscriptionId,
      couponCode: couponCode ? couponCode : null
    }
  }
  return eventToLog
}

export function createEventLogObjectFromAdmin(apparatus, subscription, profile, eventType, newSubscriptionId = null) {
  let eventToLog = {
    event: eventType,
    ownerOrganizationId: apparatus?.organizationId,
    apparatusId: apparatus?.apparatusId,
    userId: apparatus?.userId,
    properties: {
      deviceId: apparatus?.deviceInfo?.deviceId,
      deviceType: apparatus?.deviceInfo?.deviceType,
      oldSubscriptionId: subscription && subscription.activeProduct ? subscription?.activeProduct[0]?.productId : null,
      newSubscriptionId: newSubscriptionId,
      adminUserId: profile?.userId,
      adminInitiated: true
    }
  }
  return eventToLog
}

export function getEventType(oldProductId, newProductId) {
  if (oldProductId.includes('annual') || (oldProductId.includes('month') && newProductId.includes('free'))) {
    return Constants.DowngradeEventType
  } else return Constants.UpgradeEventType
}

export function validAddress(address) {
  if (address && address.line1 && address.region && address.city && address.country) return true
  return false
}

export function getOS() {
  if (navigator.userAgent.toLowerCase().includes('windows')) {
    return 'Windows'
  } else if (navigator.userAgent.toLowerCase().includes('android')) {
    return 'Android'
  } else if (navigator.userAgent.toLowerCase().match(/(ios)|(ipad)|(iphone)/)) {
    return 'iOS'
  } else {
    return 'Unknown'
  }
}

export function PortablesEnabledFF(currentAppConfig) {
  return (
    currentAppConfig &&
    currentAppConfig.featureFlags &&
    currentAppConfig.featureFlags[Constants.AddPortablesGenBtOnDashboardFF]
  )
}

export function openModal(elementId) {
  let element = document.getElementById(elementId)
  if (element) element.style.display = 'block'
}

export function closeModal(elementId) {
  let element = document.getElementById(elementId)
  if (element) element.style.display = 'none'
}

export function copyToClipboard(copyText) {
  if (copyText) {
    navigator.clipboard.writeText(copyText)
    this.$store.dispatch('displayToast', {
      title: this.$t('xSuccess'),
      message: this.$t('xInfoCopiedToClipboard'),
      variant: 'success'
    })
  }
}

/** Given an apparatusId and a list of subscriptions from the getSubscriptionDetails details endpoint data, return the subscription that matches with the apparatusId, if it exists. */
export function getSubscriptionForApparatus(apparatusId, subscriptionList) {
  for (const subscription of subscriptionList) {
    for (const apparatus of subscription.apparatuses) {
      if (apparatus.apparatusId === apparatusId) {
        return subscription
      }
    }
  }
  return
}

/** Given an apparatusId and a legacy billing data from the getSubscriptionDetails details endpoint data, return the subscription info that matches with the apparatusId, if it exists. */
export function getLegacySubscriptionForApparatus(apparatusId, legacyBillingAccountData) {
  const products = legacyBillingAccountData?.products
  const thisProduct = products?.filter(product => product.apparatusId === apparatusId)[0]
  return thisProduct
}

Vue.mixin({
  methods: {
    validState: function(formField) {
      return formField.$dirty ? !formField.$error : null
    },
    status(validation) {
      return {
        'is-invalid': validation.$error,
        'is-valid': validation.$dirty
      }
    },
    addPendingApparatus,
    canHavePaidSubscription,
    checkPendingApparatuses,
    closeModal,
    copyToClipboard,
    createEventLogObjectFromAdmin,
    createEventLogObjectFromDashboardPage,
    createEventLogObjectFromSubscriptionsPage,
    creditCardValidation,
    cvvValidation,
    consentValidation,
    deepTrim,
    DisplayAddress,
    displayExtraShortTimeWithTZ,
    displayLongDateTime,
    displayShortDate,
    displayShortDateTime,
    displayShortDateTimeWithTZ,
    displayShortTime,
    documentsStatementsPageEnabled,
    forceSignOut,
    genWithoutWifiModule,
    getBannerIconClassAndColor,
    getBannerVariant,
    getEventType,
    getOS,
    getSortOrder,
    getSubscriptionClass,
    getSubscriptionForApparatus,
    getLegacySubscriptionForApparatus,
    hasAssociatedAccount,
    hasDevice,
    messageCenterEnabled,
    notImplemented,
    openModal,
    PortablesEnabledFF,
    showBannerMessage,
    signOut,
    signOutAccountCreation,
    subscriptionActive,
    subscriptionManaged,
    subscriptionPaid,
    subscriptionPaidAndNotManaged,
    trackAppInsightsEvent,
    updateEmail,
    validAddress,
    virtualPowerPlantEnabled,
    g3DeviceFeatureFlagEnabled,
    vueAppApiBaseAddress,
    vueAppBaseAddress
  }
})
