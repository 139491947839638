var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ml-3 mr-3", attrs: { rounded: "lg" } }, [
    _c("div", { staticClass: "row no-gutters mb-2 mt-4" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "h1",
          {
            staticClass: "mb-0 pb-0",
            attrs: { "data-test-hook": "pageTitle" }
          },
          [
            _c("i", {
              staticClass: "far fa-file-alt pr-1",
              attrs: { "data-test-hook": "titleIcon" }
            }),
            _c(
              "span",
              {
                attrs: { "data-test-hook": "docs-card-title-account-settings" }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xDocumentsStatements")))]
            )
          ]
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "card", attrs: { "header-bg-variant": "white" } },
      [
        _c("div", { staticClass: "card-body" }, [
          _vm.invoicesLoading
            ? _c("div", { staticClass: "row mt-2" }, [_vm._m(0)])
            : !_vm.invoicesLoading && !_vm.invoices
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-test-hook":
                          "docs-no-statements-text-account-settings"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("xNoStatementsAvailable")))]
                  )
                ])
              ])
            : _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.invoices, function(invoice, index) {
                  return _c("div", { key: index, staticClass: "col-12" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "#",
                          "data-test-hook": "docs-invoice-account-settings"
                        },
                        on: {
                          click: function($event) {
                            return _vm.downloadInvoice(invoice)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "far fa-file-pdf mr-1" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xInvoice")) +
                            " " +
                            _vm._s(
                              _vm
                                .$moment(invoice.date)
                                .format(_vm.$t("xShortDate"))
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                }),
                0
              )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col text-center float-center" }, [
      _c("div", {
        staticClass: "spinner-border text-warning",
        staticStyle: { width: "5rem", height: "5rem" },
        attrs: {
          label: "Spinning",
          "data-test-hook": "docs-loading-spinner-account-settings"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }